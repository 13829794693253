import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Chip,
    createTheme,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HotelType } from '../model/data';
import { getUser, getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';
interface Props {
    open: boolean;
    handleClose: () => void;
}

export default function ProfileForm(props: Props) {
    const { open, handleClose } = props;
    const userInfo = getUser();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleError = useHandleError();
    const { enqueueSnackbar } = useSnackbar();
    const theme = createTheme();
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const handleChange = (_event: ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    interface IFormInput {
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
    }

    const {
        control,
        watch,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm<IFormInput>({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
    });

    const onSubmit = (data: IFormInput) => {
        const sendData = {
            password: data.currentPassword,
            newpasswd: data.newPassword,
            uuid: getUUID(),
        };

        setIsSubmitting(true);
        axios
            .post('changepassword', sendData)
            .then((_response: AxiosResponse<HotelType>) => {
                setIsSubmitting(false);
                reset();
                setExpanded(false);
                enqueueSnackbar('Password changed successfully.', { variant: 'success' });
            })
            .catch((error: AxiosError) => {
                setIsSubmitting(false);
                handleError(error, 'Cannot change password.');
            });
    };

    React.useEffect(() => {
        if (open) {
            reset();
            setExpanded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const newPassword = watch('newPassword');

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                Profile Details
                {handleClose ? (
                    <IconButton
                        aria-label="close"
                        sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                        onClick={handleClose}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="h5" gutterBottom>
                    {userInfo.name}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {userInfo.email}
                </Typography>
                <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                    <Grid item xs={4}>
                        <Typography variant="body1">Rooms Limit</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1">{userInfo.roomsLimit}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                    <Grid item xs={4}>
                        <Typography variant="body1">Subscription Start</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1">{dayjs(userInfo.subscriptionStart, 'YYYY-MM-DD').format('DD-MM-YYYY')}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                    <Grid item xs={4}>
                        <Typography variant="body1">Subscription End</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1">{dayjs(userInfo.subscriptionEnd, 'YYYY-MM-DD').format('DD-MM-YYYY')}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                    <Grid item xs={4}>
                        <Typography variant="body1">Subscriptions</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Stack spacing={1} direction="row">
                            {userInfo.subscriptionType.split(',').map((type) => (
                                <Chip key={type} label={type} />
                            ))}
                        </Stack>
                    </Grid>
                </Grid>

                <Accordion sx={{ marginTop: theme.spacing(2) }} expanded={expanded} onChange={handleChange}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="body1">Change Password</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form id="profile-form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <Controller
                                name="currentPassword"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        autoComplete="new-password"
                                        id="currentPassword"
                                        label="Current Password"
                                        fullWidth
                                        required
                                        variant="outlined"
                                        size="small"
                                        type="password"
                                        error={!!errors.currentPassword}
                                        helperText={errors?.currentPassword?.message}
                                        margin="dense"
                                    />
                                )}
                            />

                            <Controller
                                name="newPassword"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        autoComplete="off"
                                        id="newPassword"
                                        label="New Password"
                                        fullWidth
                                        required
                                        variant="outlined"
                                        size="small"
                                        type="password"
                                        error={!!errors.newPassword}
                                        helperText={errors?.newPassword?.message}
                                        margin="dense"
                                    />
                                )}
                            />

                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                    validate: (value) => value === newPassword || 'Passwords does not match',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        autoComplete="off"
                                        id="confirmPassword"
                                        label="Confirm Password"
                                        fullWidth
                                        required
                                        variant="outlined"
                                        size="small"
                                        type="password"
                                        error={!!errors.confirmPassword}
                                        helperText={errors?.confirmPassword?.message}
                                        margin="dense"
                                    />
                                )}
                            />

                            <Button
                                sx={{ marginTop: theme.spacing(1) }}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}>
                                Change Password
                            </Button>
                            {isSubmitting && <LinearProgress sx={{ marginTop: theme.spacing(1) }} />}
                        </form>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
        </Dialog>
    );
}
