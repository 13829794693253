import CloseIcon from '@mui/icons-material/Close';
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    LinearProgress,
    TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import equal from 'fast-deep-equal';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LockDeviceType, OperationType, RoomFormType, RoomType } from '../model/data';
import { getUUID, getUser } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

interface Props {
    room?: RoomType;
    hotelId?: string;
    handleOperation: (operation: OperationType, room?: RoomType) => void;
}

function RoomForm(props: Props) {
    const { hotelId, room, handleOperation } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleError = useHandleError();
    const userInfo = getUser();
    const [lockOptions, setLockOptions] = useState<LockDeviceType[]>([]);

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        getValues,
        setValue,
    } = useForm<RoomFormType>({
        defaultValues: {
            roomName: room?.roomName || '',
            roomActive: !!room?.roomActive,
            roomLock: !!room?.roomLock,
            unlatchMode: !!room?.unlatchMode,
            roomLockDeviceIds: room?.roomLockDeviceIds
                ? lockOptions.filter((lock) => room?.roomLockDeviceIds.split(',').map(Number).indexOf(Number(lock.smartlockId)) !== -1)
                : [],
            roomLockToken: room?.roomLockToken || '',
        },
    });

    const roomLock = !!watch('roomLock', getValues('roomLock'));
    const roomLockToken = watch('roomLockToken', getValues('roomLockToken'));

    React.useEffect(() => {
        if (/lock/i.test(userInfo.subscriptionType)) {
            axios
                .get('https://api.nuki.io/smartlock', {
                    headers: {
                        Authorization: `token ${roomLockToken}`,
                    },
                })
                .then((res) => {
                    const lockIds: LockDeviceType[] = res.data.map((lock: LockDeviceType) =>
                        (({ name, smartlockId }) => ({ name, smartlockId }))(lock)
                    );
                    setLockOptions(lockIds);
                    if (room) {
                        setValue(
                            'roomLockDeviceIds',
                            lockIds.filter(
                                (lock) => room?.roomLockDeviceIds.split(',').map(Number).indexOf(Number(lock.smartlockId)) !== -1
                            )
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomLockToken]);

    const onSubmit = (data: RoomFormType) => {
        setIsSubmitting(true);
        const sendData = {
            ...data,
            hotelId: hotelId,
            roomLockDeviceIds: data.roomLockDeviceIds.map((lock) => lock.smartlockId).join(),
            uuid: getUUID(),
        };

        if (room == null) {
            axios
                .post('rooms', sendData)
                .then((response: AxiosResponse<RoomType>) => {
                    handleOperation('ADD', response.data);
                })
                .catch((error: AxiosError) => {
                    setIsSubmitting(false);
                    handleError(error, 'Cannot save room.');
                });
        } else {
            // Check if we changed anything
            let updatedData = { ...data, id: room.id };
            if (!equal(updatedData, room)) {
                setIsSubmitting(true);
                const sendData = {
                    ...data,
                    roomLockDeviceIds: data.roomLockDeviceIds.map((lock) => lock.smartlockId).join(),
                    uuid: getUUID(),
                    id: room.id,
                };

                axios
                    .put('rooms', sendData)
                    .then((response: AxiosResponse<RoomType>) => {
                        handleOperation('UPDATE', response.data);
                    })
                    .catch((error: AxiosError) => {
                        setIsSubmitting(false);
                        handleError(error, 'Cannot update room.');
                    });
            }
            handleOperation('CLOSE', undefined);
        }
    };

    return (
        <Dialog
            onClose={(_event: object, reason: string) => {
                if (reason !== 'backdropClick') {
                    handleOperation('CLOSE');
                }
            }}
            open={true}>
            <DialogTitle>
                {room ? 'Edit Room' : 'New Room'}
                <IconButton
                    aria-label="close"
                    onClick={() => handleOperation('CLOSE')}
                    sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                    size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <form id="room-form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <Controller
                        name="roomName"
                        control={control}
                        rules={{
                            required: 'This field is required',
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="name"
                                autoComplete="new-password"
                                label="Name"
                                required
                                fullWidth
                                variant="outlined"
                                size="small"
                                error={!!errors.roomName}
                                helperText={errors?.roomName?.message}
                                margin="dense"
                            />
                        )}
                    />

                    <Controller
                        name="roomActive"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                {...field}
                                style={{ width: '100%' }}
                                control={
                                    <Checkbox color="primary" onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />
                                }
                                label="Active Room"
                                labelPlacement="end"
                            />
                        )}
                    />

                    {/lock/i.test(userInfo.subscriptionType) && (
                        <>
                            <Controller
                                name="roomLock"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        {...field}
                                        style={{ width: '100%' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onChange={(e) => field.onChange(e.target.checked)}
                                                checked={field.value}
                                            />
                                        }
                                        label="Enable Lock"
                                        labelPlacement="end"
                                    />
                                )}
                            />
                            {roomLock && (
                                <>
                                    <Controller
                                        name="unlatchMode"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                {...field}
                                                style={{ width: '100%' }}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                        checked={field.value}
                                                    />
                                                }
                                                label="Unlatch door during unlock"
                                                labelPlacement="end"
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="roomLockToken"
                                        control={control}
                                        rules={{
                                            required: 'This field is required',
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="name"
                                                autoComplete="new-password"
                                                label="Lock Token"
                                                required
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                error={!!errors.roomLockToken}
                                                helperText={errors?.roomLockToken?.message}
                                                margin="dense"
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="roomLockDeviceIds"
                                        control={control}
                                        rules={{
                                            required: 'This field is required',
                                        }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                autoComplete
                                                autoHighlight
                                                multiple
                                                size="small"
                                                id="roomLockDeviceIds"
                                                onChange={(_, data) => field.onChange(data)}
                                                options={lockOptions}
                                                getOptionLabel={(option) => option.name}
                                                isOptionEqualToValue={(option, value) =>
                                                    value === undefined || option.smartlockId === value.smartlockId
                                                }
                                                filterSelectedOptions
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        variant="outlined"
                                                        label="Lock Device Ids"
                                                        error={!!errors.roomLockDeviceIds}
                                                        helperText={errors?.roomLockDeviceIds?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {isSubmitting && <LinearProgress sx={{ mt: 1 }} />}
                </form>
            </DialogContent>

            <DialogActions>
                {room != null ? (
                    <Button form="room-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                        Update
                    </Button>
                ) : (
                    <Button form="room-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                        Create
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default RoomForm;
