import { createTheme, Grid, Paper, Typography } from '@mui/material';

function QRCodeLandPage() {
    const theme = createTheme();

    return (
        <Grid sx={{ width: '100%', padding: theme.spacing(2) }} container spacing={3} justifyContent="space-around">
            <Grid item xs={12}>
                <Paper sx={{ padding: theme.spacing(2), textAlign: 'center', justifyContent: 'center' }} elevation={3}>
                    <Typography variant="h2">You should open this link with the HotelUp app in iOS or Android.</Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default QRCodeLandPage;
