import { createTheme, Grid, Paper, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import HeaderNavigation from '../components/headerNavigation';

function NoMatch() {
    let location = useLocation();
    const theme = createTheme();

    return (
        <>
            <HeaderNavigation />
            <Grid sx={{ width: '100%', padding: theme.spacing(2) }} container spacing={3} justifyContent="space-around">
                <Grid item xs={12}>
                    <Paper sx={{ padding: theme.spacing(2), textAlign: 'center', justifyContent: 'center' }} elevation={3}>
                        <Typography variant="h2">
                            No match for <code>{location.pathname}</code>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default NoMatch;
