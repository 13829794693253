import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Box,
    Button,
    CircularProgress,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import HeaderNavigation from '../components/headerNavigation';
import RoomForm from '../components/roomForm';
import { HotelType, OperationType, RoomType } from '../model/data';
import { getUser, getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

function Rooms() {
    const theme = createTheme();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [hotel, setHotel] = useState<HotelType | undefined>();
    const [rooms, setRooms] = useState<RoomType[]>([]);
    const [room, setRoom] = useState<RoomType | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isRoomModalOpen, setIsRoomModalOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const handleError = useHandleError();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const userInfo = getUser();

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
        axios
            .get('hotels', {
                params: {
                    uuid: getUUID(),
                    id: id,
                },
            })
            .then((response: AxiosResponse<HotelType>) => {
                setHotel(response.data);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem fetching hotel.');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setIsLoading(true);
        hotel &&
            axios
                .get('rooms', {
                    params: {
                        uuid: getUUID(),
                        hotelId: hotel.id,
                    },
                })
                .then((response: AxiosResponse<RoomType[]>) => {
                    setIsLoading(false);
                    setRooms(response.data);
                })
                .catch((error: AxiosError) => {
                    setIsLoading(false);
                    handleError(error, 'Problem fetching rooms.');
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotel]);

    function editRoom(room: RoomType) {
        setRoom(room);
        setIsRoomModalOpen(true);
    }

    function deleteRoom(room: RoomType) {
        axios
            .delete('rooms', {
                params: {
                    uuid: getUUID(),
                    id: room.id,
                },
            })
            .then(() => {
                setOpenDeleteDialog(false);
                setRooms(rooms.filter((itm) => itm.id !== room.id));
            })
            .catch((error: AxiosError) => {
                setOpenDeleteDialog(false);
                handleError(error, 'Cannot delete room.');
            });
    }

    const createNewRoom = () => {
        setRoom(undefined);
        setIsRoomModalOpen(true);
    };

    const handleRoomOperation = (operation: OperationType, data?: RoomType) => {
        switch (operation) {
            case 'ADD':
                data && setRooms([...rooms, data]);
                break;
            case 'UPDATE':
                data && setRooms(rooms.map((itm) => (itm.id === data.id ? data : itm)));
                break;
            case 'CLOSE':
                break;
        }
        setIsRoomModalOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <>
            <HeaderNavigation />

            <Grid
                sx={{ width: '100%', paddingRight: theme.spacing(2), paddingLeft: theme.spacing(2) }}
                container
                spacing={1}
                justifyContent="space-between">
                <Grid item xs={12}>
                    <Paper sx={{ padding: theme.spacing(2) }}>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h3">Rooms Manager for {hotel?.hotelName}</Typography>
                            </Grid>
                            <Grid item>
                                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        sx={{ marginInlineEnd: theme.spacing(2) }}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => hotel?.id && history.push(`/hotels/reservations/${hotel.id}`)}>
                                        Reservations
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={createNewRoom}>
                                        New Room
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ width: '100%' }}>
                        <TableContainer component={Paper} style={{ maxHeight: 650 }}>
                            <Table stickyHeader aria-label="Rooms">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="right">Active</TableCell>
                                        {/lock/i.test(userInfo.subscriptionType) && <TableCell align="right">Lock enabled</TableCell>}
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading ? (
                                        <TableRow key="loading">
                                            <TableCell colSpan={3} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        rooms &&
                                        rooms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row.roomName}</TableCell>
                                                <TableCell align="right">
                                                    {row.roomActive ? (
                                                        <CheckCircleIcon fontSize="small" />
                                                    ) : (
                                                        <CancelIcon fontSize="small" style={{ color: red[500] }} />
                                                    )}
                                                </TableCell>
                                                {/lock/i.test(userInfo.subscriptionType) && (
                                                    <TableCell align="right">
                                                        {row.roomLock ? (
                                                            <CheckCircleIcon fontSize="small" />
                                                        ) : (
                                                            <CancelIcon fontSize="small" style={{ color: red[500] }} />
                                                        )}
                                                    </TableCell>
                                                )}
                                                <TableCell align="center">
                                                    <Stack spacing={1} justifyContent="center" direction="row">
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => {
                                                                setRoom(row);
                                                                setOpenDeleteDialog(true);
                                                            }}>
                                                            Delete
                                                        </Button>
                                                        <Button variant="contained" color="primary" onClick={() => editRoom(row)}>
                                                            Edit
                                                        </Button>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={rooms.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {isRoomModalOpen && <RoomForm hotelId={hotel && hotel.id} room={room} handleOperation={handleRoomOperation} />}
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>{room && `Are you sure you want to delete room ${room.roomName} ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you delete the room, it will also delete all the reservations that are connected to this room.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>No</Button>
                    <Button onClick={() => room && deleteRoom(room)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Rooms;
