import AddIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Card, CardActionArea, CardContent, CardHeader, Chip, createTheme, Grid, Stack, Typography } from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderNavigation from '../components/headerNavigation';
import HotelForm from '../components/hotelForm';
import { HotelType, OperationType, RestaurantType } from '../model/data';
import { amenities } from '../utils/const';
import { getHotelNotifications, getRegistrationToken, getUser, getUUID, storeHotelNotifications } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

function Hotels() {
    const theme = createTheme();
    const history = useHistory();
    const [isHotelModalOpen, setIsHotelModalOpen] = useState(false);
    const [hotel, setHotel] = useState<HotelType | undefined>();
    const [hotels, setHotels] = useState<HotelType[]>([]);
    const handleError = useHandleError();
    const [restaurants, setRestaurants] = useState<RestaurantType[]>([]);
    const userInfo = getUser();

    React.useEffect(() => {
        axios
            .get('hotels', {
                params: {
                    uuid: getUUID(),
                },
            })
            .then((response: AxiosResponse<HotelType[]>) => {
                setHotels(response.data);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem fetching hotels.');
            });

        if (/restaurant/i.test(userInfo.subscriptionType)) {
            axios
                .get('restaurants', {
                    params: {
                        uuid: getUUID(),
                    },
                })
                .then((response: AxiosResponse<RestaurantType[]>) => {
                    setRestaurants(response.data);
                })
                .catch((error: AxiosError) => {
                    handleError(error, 'Problem fetching restaurants.');
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNewHotel = () => {
        setHotel(undefined);
        setIsHotelModalOpen(true);
    };

    const editRestaurant = (hotel: HotelType) => {
        setHotel(hotel);
        setIsHotelModalOpen(true);
    };

    const handleHotelOperation = (operation: OperationType, data?: HotelType) => {
        switch (operation) {
            case 'ADD':
                if (data) {
                    setHotels([...hotels, data]);

                    let hotelNotifications = getHotelNotifications();
                    const hotel = hotelNotifications.find((hotel) => hotel.id.toString() === data.id.toString());
                    if (!hotel) {
                        hotelNotifications.push({ id: data.id, isChecked: true });
                        storeHotelNotifications(hotelNotifications);
                        hotelNotifications = getHotelNotifications();
                    }
                    const topic = `${getUUID()}_hotel_${data.id}`;
                    axios
                        .post('subscribe', {
                            registration_token: getRegistrationToken(),
                            topic: topic,
                        })
                        .then(() => {
                            storeHotelNotifications(
                                hotelNotifications.map((setting) =>
                                    setting.id.toString() === data.id.toString() ? { ...setting, isChecked: true } : setting
                                )
                            );
                        })
                        .catch((error: AxiosError) => {
                            handleError(error, 'Problem subscribe to hotel notification');
                            storeHotelNotifications(
                                hotelNotifications.map((setting) =>
                                    setting.id.toString() === data.id.toString() ? { ...setting, isChecked: false } : setting
                                )
                            );
                        });
                }

                break;
            case 'UPDATE':
                data && setHotels(hotels.map((rest) => (rest.id === data.id ? data : rest)));
                break;
            case 'DELETE':
                if (data) {
                    setHotels(hotels.filter((rest) => rest.id !== data.id));

                    let hotelNotifications = getHotelNotifications();
                    const topic = `${getUUID()}_hotel_${data.id}`;
                    axios
                        .post('unsubscribe', {
                            registration_token: getRegistrationToken(),
                            topic: topic,
                        })
                        .then(() => {
                            storeHotelNotifications(hotelNotifications.filter((rest) => rest.id.toString() !== data.id.toString()));
                        })
                        .catch((error: AxiosError) => {
                            handleError(error, 'Problem unsubscribe from hotel notification');
                        });
                }

                break;
            case 'CLOSE':
                break;
        }
        setIsHotelModalOpen(false);
    };

    return (
        <>
            <HeaderNavigation />
            <Grid
                sx={{ width: '100%', paddingRight: theme.spacing(2), paddingLeft: theme.spacing(2) }}
                container
                spacing={1}
                justifyContent="space-between">
                <Grid item xs={12} sx={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start' }}>
                    {hotels &&
                        hotels.map((hotel) => (
                            <Card
                                sx={{
                                    width: '350px',
                                    marginRight: theme.spacing(2),
                                    marginBottom: theme.spacing(2),
                                    minHeight: '600px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                                key={hotel.id}>
                                <CardActionArea onClick={() => editRestaurant(hotel)}>
                                    <CardHeader
                                        sx={{ paddingBottom: theme.spacing(1) }}
                                        title={hotel.hotelName}
                                        subheader={hotel.hotelInfo}
                                    />

                                    <CardContent>
                                        <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography variant="body2">Email</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="body2">{hotel.hotelEmail}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography variant="body2">Phone</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="body2">{hotel.hotelPhone}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography variant="body2">WiFi SSID</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="body2">{hotel.wifiSsid}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography variant="body2">Rooms</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="body2">{hotel.rooms}</Typography>
                                            </Grid>
                                        </Grid>
                                        {/restaurant/i.test(userInfo.subscriptionType) ? (
                                            <Grid
                                                sx={{ paddingTop: theme.spacing(1) }}
                                                container
                                                spacing={1}
                                                justifyContent="space-around"
                                                alignItems="center">
                                                <Grid item xs={4}>
                                                    <Typography variant="body2">Restaurants</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Stack alignItems="flex-start" spacing={1}>
                                                        {hotel.restaurantsId &&
                                                            restaurants
                                                                .filter(
                                                                    (rest) =>
                                                                        hotel.restaurantsId
                                                                            .split(',')
                                                                            .map(Number)
                                                                            .indexOf(Number(rest.id)) !== -1
                                                                )
                                                                .map((rest) => {
                                                                    return <Chip key={rest.id} label={rest.name} />;
                                                                })}
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}

                                        <Grid
                                            container
                                            spacing={1}
                                            sx={{ paddingTop: theme.spacing(3) }}
                                            justifyContent="space-around"
                                            alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography variant="body2">Amenities</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Stack alignItems="flex-start" spacing={1}>
                                                    {hotel.amenities &&
                                                        amenities.map((element) => {
                                                            return hotel.amenities[element.id] ? (
                                                                <Chip
                                                                    key={element.id}
                                                                    color="success"
                                                                    icon={<CheckCircleIcon />}
                                                                    label={element.title}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    key={element.id}
                                                                    color="error"
                                                                    icon={<CancelIcon />}
                                                                    label={element.title}
                                                                />
                                                            );
                                                        })}
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                                <CardContent sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button variant="contained" size="small" onClick={() => history.push(`/hotels/rooms/${hotel.id}`)}>
                                        Rooms Manager
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => history.push(`/hotels/reservations/${hotel.id}`)}>
                                        Reservations
                                    </Button>
                                </CardContent>
                            </Card>
                        ))}
                    <Card
                        sx={{
                            width: '350px',
                            marginRight: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                            minHeight: '600px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}>
                        <CardContent sx={{ height: '100%' }}>
                            <Grid container spacing={1} sx={{ height: '100%' }} alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Button onClick={createNewHotel} variant="contained" color="primary" startIcon={<AddIcon />}>
                                        New Hotel
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <HotelForm open={isHotelModalOpen} hotel={hotel} handleOperation={handleHotelOperation} />
        </>
    );
}

export default Hotels;
