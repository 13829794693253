import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import equal from 'fast-deep-equal';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Resizer from 'react-image-file-resizer';
import { NumericFormat } from 'react-number-format';
import { MenuItemFormType, MenuItemType, OperationType } from '../model/data';
import { API_URL } from '../utils/const';
import { getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

interface Props {
    menuItem?: MenuItemType;
    menuId?: string;
    handleOperation: (operation: OperationType, menuItem?: MenuItemType) => void;
}

function MenuItemForm(props: Props) {
    const { menuId, menuItem, handleOperation } = props;
    const theme = createTheme();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [image, setImage] = useState(menuItem?.image);
    const [imageURL, setImageUrl] = useState(menuItem ? API_URL + menuItem?.image : undefined);
    const [imageUploaded, setImageUploaded] = useState(false);
    const handleError = useHandleError();

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<MenuItemFormType>({
        defaultValues: {
            name: menuItem?.name || '',
            description: menuItem?.description || '',
            price: menuItem?.price,
            category: menuItem?.category || null,
            type: menuItem?.type || '',
        },
    });

    const onSubmit = (data: MenuItemFormType) => {
        setIsSubmitting(true);
        if (menuItem == null) {
            const sendData = {
                ...data,
                menuId: menuId,
                uuid: getUUID(),
            };

            if (imageUploaded && image) {
                sendData.image = image;
            }

            axios
                .post('menuitems', sendData)
                .then((response: AxiosResponse<MenuItemType>) => {
                    handleOperation('ADD', response.data);
                })
                .catch((error: AxiosError) => {
                    setIsSubmitting(false);
                    handleError(error, 'Cannot save menuItem.');
                });
        } else {
            if (imageUploaded) {
                const sendData = {
                    ...data,
                    image: image,
                    uuid: getUUID(),
                    id: menuItem.id,
                };

                axios
                    .put('menuitems', sendData)
                    .then((response: AxiosResponse<MenuItemType>) => {
                        handleOperation('UPDATE', response.data);
                    })
                    .catch((error: AxiosError) => {
                        setIsSubmitting(false);
                        handleError(error, 'Cannot update menuItem.');
                    });
            } else {
                // Check if we changed anything
                let updatedData = { ...menuItem, ...data };
                if (!equal(updatedData, menuItem)) {
                    setIsSubmitting(true);
                    const { image, ...dataWithoutImage } = data;
                    const sendData = {
                        ...dataWithoutImage,
                        uuid: getUUID(),
                        id: menuItem.id,
                    };

                    axios
                        .put('menuitems', sendData)
                        .then((response: AxiosResponse<MenuItemType>) => {
                            handleOperation('UPDATE', response.data);
                        })
                        .catch((error: AxiosError) => {
                            setIsSubmitting(false);
                            handleError(error, 'Cannot update menuItem.');
                        });
                }
            }
            handleOperation('CLOSE', undefined);
        }
    };

    const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            //Resize image
            Resizer.imageFileResizer(
                e.target.files[0],
                400,
                300,
                'JPEG',
                100,
                0,
                (uri) => {
                    //Upload resized image
                    const data = new FormData();
                    data.append('image', uri as File);
                    data.append('uuid', getUUID() || '');
                    axios
                        .post('uploadimage', data, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                        .then((response: AxiosResponse<MenuItemType>) => {
                            setImage(response.data.image);
                            setImageUrl(API_URL + response.data.image);
                            setImageUploaded(true);
                        })
                        .catch((error: AxiosError) => {
                            handleError(error, 'Cannot upload image.');
                        });
                },
                'file'
            );
        }
    };

    const categories = [
        'Appetizers',
        'Salads',
        'Deeps',
        'Risotto',
        'Pasta',
        'Main Dishes',
        'Drinks',
        'Desserts',
        'Breakfast',
        'Pizza',
        'Coffee',
    ];

    return (
        <Dialog
            onClose={(_event: object, reason: string) => {
                if (reason !== 'backdropClick') {
                    handleOperation('CLOSE');
                }
            }}
            open={true}>
            <DialogTitle>
                {menuItem ? 'Edit Menu Item' : 'New Menu Item'}
                <IconButton
                    aria-label="close"
                    onClick={() => handleOperation('CLOSE')}
                    sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                    size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <form id="menu-item-form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <Grid container spacing={1} justifyContent="space-around" alignContent="center">
                        <Grid item xs={8}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="name"
                                        label="Name"
                                        autoComplete="new-password"
                                        required
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        error={!!errors.name}
                                        helperText={errors?.name?.message}
                                        margin="dense"
                                    />
                                )}
                            />

                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="description"
                                        label="Description"
                                        required
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        size="small"
                                        error={!!errors.description}
                                        helperText={errors?.description?.message}
                                        margin="dense"
                                    />
                                )}
                            />

                            <Controller
                                name="price"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field: { ref, ...props } }) => (
                                    <NumericFormat
                                        {...props}
                                        id="price"
                                        label="Price"
                                        required
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        error={!!errors.price}
                                        helperText={errors?.price?.message}
                                        margin="dense"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">&euro;</InputAdornment>,
                                        }}
                                        customInput={TextField}
                                        decimalScale={2}
                                    />
                                )}
                            />

                            <Controller
                                name="category"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        autoComplete
                                        size="small"
                                        id="category"
                                        options={categories}
                                        onChange={(_, data) => field.onChange(data)}
                                        value={field.value}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                margin="dense"
                                                variant="outlined"
                                                error={!!errors.category}
                                                helperText={errors?.category?.message}
                                                label="Category"
                                            />
                                        )}
                                    />
                                )}
                            />

                            <Controller
                                name="type"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="type"
                                        label="Special Notes"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        error={!!errors.type}
                                        helperText={errors?.type?.message}
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(0.5) }} fullWidth>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={(e) => uploadImage(e)}
                                    id="menu-image"
                                    type="file"
                                />
                                <label htmlFor="menu-image">
                                    <Button variant="contained" component="span">
                                        Upload Image
                                    </Button>
                                </label>
                            </FormControl>
                            <FormControl sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(0.5) }} fullWidth>
                                {image && <img alt="menu" src={imageURL} />}
                            </FormControl>
                        </Grid>
                    </Grid>
                    {isSubmitting && <LinearProgress sx={{ mt: 1 }} />}
                </form>
            </DialogContent>

            <DialogActions>
                {menuItem != null ? (
                    <Button form="menu-item-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                        Update
                    </Button>
                ) : (
                    <Button form="menu-item-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                        Create
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default MenuItemForm;
