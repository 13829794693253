import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import equal from 'fast-deep-equal';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MenuType, OperationType } from '../model/data';
import { getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

interface Props {
    menu?: MenuType;
    restaurantId?: string;
    handleOperation: (operation: OperationType, menu?: MenuType) => void;
}

function MenuForm(props: Props) {
    const { restaurantId, menu, handleOperation } = props;
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleError = useHandleError();

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<MenuType>({
        defaultValues: {
            menuName: menu?.menuName || '',
            active: !!menu?.active,
        },
    });

    const onSubmit = (data: MenuType) => {
        setIsSubmitting(true);
        const sendData = {
            ...data,
            restaurantId: restaurantId,
            uuid: getUUID(),
        };
        if (menu == null) {
            axios
                .post('menu', sendData)
                .then((response: AxiosResponse<MenuType>) => {
                    handleOperation('ADD', response.data);
                })
                .catch((error: AxiosError) => {
                    setIsSubmitting(false);
                    handleError(error, 'Cannot save menu.');
                });
        } else {
            // Check if we changed anything
            let updatedData = { ...data, id: menu.id };
            if (!equal(updatedData, menu)) {
                const sendData = {
                    ...data,
                    uuid: getUUID(),
                    id: menu.id,
                };

                axios
                    .put('menu', sendData)
                    .then((response: AxiosResponse<MenuType>) => {
                        handleOperation('UPDATE', response.data);
                    })
                    .catch((error: AxiosError) => {
                        setIsSubmitting(false);
                        handleError(error, 'Cannot update menu.');
                    });
            }
            handleOperation('CLOSE', undefined);
        }
    };

    const handleDelete = (menu: MenuType) => {
        setOpenDeleteDialog(false);
        setIsSubmitting(true);
        axios
            .delete('menu', {
                params: {
                    uuid: getUUID(),
                    id: menu.id,
                },
            })
            .then(() => {
                handleOperation('DELETE', menu);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Cannot delete menu.');
            });
    };

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <>
            <Dialog
                onClose={(_event: object, reason: string) => {
                    if (reason !== 'backdropClick') {
                        handleOperation('CLOSE');
                    }
                }}
                open={true}>
                <DialogTitle>
                    {menu ? 'Edit Menu' : 'New Menu'}
                    <IconButton
                        aria-label="close"
                        onClick={() => handleOperation('CLOSE')}
                        sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <form id="menu-form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <Controller
                            name="menuName"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="name"
                                    label="Name"
                                    autoComplete="new-password"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.menuName}
                                    helperText={errors?.menuName?.message}
                                    margin="dense"
                                />
                            )}
                        />

                        <Controller
                            name="active"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    {...field}
                                    style={{ width: '100%' }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            checked={field.value}
                                        />
                                    }
                                    label="Active Menu"
                                    labelPlacement="end"
                                />
                            )}
                        />

                        {isSubmitting && <LinearProgress sx={{ mt: 1 }} />}
                    </form>
                </DialogContent>

                <DialogActions>
                    {menu != null ? (
                        <Stack sx={{ width: '100%' }} spacing={1} direction="row" justifyContent="space-between">
                            <Button variant="contained" color="secondary" onClick={() => setOpenDeleteDialog(true)} disabled={isSubmitting}>
                                Delete
                            </Button>
                            <Button form="menu-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                Update
                            </Button>
                        </Stack>
                    ) : (
                        <Button form="menu-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                            Create
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>{menu && `Are you sure you want to delete menu ${menu.menuName} ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you delete the menu, it will also delete all the menu items that are connected to this menu.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>No</Button>
                    <Button onClick={() => menu && handleDelete(menu)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default MenuForm;
