import axios from 'axios';
import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loginState } from './model/states';
import Amenities from './pages/amenities';
import NoMatch from './pages/error';
import Hotels from './pages/hotels';
import Login from './pages/login';
import Menu from './pages/menu';
import Orders from './pages/orders';
import QRCodeLandPage from './pages/qrcodelanding';
import Reservations from './pages/reservations';
import Restaurants from './pages/restaurants';
import Rooms from './pages/rooms';
import { API_URL } from './utils/const';
import {
    askNotificationPermission,
    initializeNotificationSettings,
    isNotificationSettingsSet,
    resubscribeToNotificationSettings,
} from './utils/helpers';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { getAuthorizationBearer, getRegistrationToken, getUser, setRegistrationToken } from './utils/storageUtils';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + getAuthorizationBearer();

function App() {
    const isLoggedIn = useRecoilValue(loginState);
    const userInfo = getUser();

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: 'AIzaSyDAwpTkr-F5XALks0XF8N7oFIiW5UCYGTI',
        authDomain: 'hotelup-bdf27.firebaseapp.com',
        projectId: 'hotelup-bdf27',
        storageBucket: 'hotelup-bdf27.appspot.com',
        messagingSenderId: '462928491215',
        appId: '1:462928491215:web:87486a96ef6d971641bdba',
        measurementId: 'G-V0GRQ0CHTP',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    React.useEffect(() => {
        if (isLoggedIn) {
            // Add the public key generated from the console here.
            getToken(messaging, { vapidKey: 'BJ_hThyi8imERTOZ6sFEP_aIP_kfU8AW0jmcTijhI73s5V904Q7W2O_UEhde6d24fB5EgyKTbmnHk0GE82ggFNA' })
                .then((currentToken) => {
                    if (currentToken) {
                        // Check if it is the first time we login
                        if (!isNotificationSettingsSet()) {
                            initializeNotificationSettings();
                            resubscribeToNotificationSettings();
                        }

                        // Check if registration token changed and resubscribe to topics if changed.
                        if (currentToken !== getRegistrationToken()) {
                            console.log('Registration Token changed');
                            setRegistrationToken(currentToken);
                            resubscribeToNotificationSettings();
                        }
                    } else {
                        // Show permission request UI
                        console.log('No registration token available. Request permission to generate one.');
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    React.useEffect(() => {
        if (Notification.permission === 'denied' || Notification.permission === 'default') {
            askNotificationPermission();
        }
    }, []);

    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/restaurants">
                        {isLoggedIn && /restaurant/i.test(userInfo.subscriptionType) ? <Restaurants /> : <Login />}
                    </Route>
                    <Route exact path="/orders">
                        {isLoggedIn && /restaurant/i.test(userInfo.subscriptionType) ? <Orders /> : <Login />}
                    </Route>
                    <Route exact path="/hotels/reservations/:id">
                        {isLoggedIn ? <Reservations /> : <Login />}
                    </Route>
                    <Route exact path="/hotels">
                        {isLoggedIn ? <Hotels /> : <Login />}
                    </Route>
                    <Route exact path="/hotels/rooms/:id">
                        {isLoggedIn ? <Rooms /> : <Login />}
                    </Route>
                    <Route exact path="/restaurants/menu/:id">
                        {isLoggedIn && /restaurant/i.test(userInfo.subscriptionType) ? <Menu /> : <Login />}
                    </Route>
                    <Route exact path="/">
                        {isLoggedIn ? <Redirect to="/hotels" /> : <Login />}
                    </Route>
                    <Route exact path="/amenities">
                        {isLoggedIn && /amenities/i.test(userInfo.subscriptionType) ? <Amenities /> : <Login />}
                    </Route>
                    <Route path="/qrcode">
                        <QRCodeLandPage />
                    </Route>
                    <Route path="*">
                        <NoMatch />
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;
