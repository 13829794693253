import { AmenitiesEnum, AmenitiesType, AmenityNotificationMessages, UserInfoType } from '../model/data';

export const API_URL: string = process.env.REACT_APP_API_URL || 'https://hotelupdev.poseidwn.tech/api';
export const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT || 'prod';

export const amenities: AmenitiesType[] = (Object.keys(AmenitiesEnum) as Array<keyof typeof AmenitiesEnum>).map((amenity) => ({
    title: AmenitiesEnum[amenity],
    id: amenity,
}));
export const amenitiesOptions: AmenityNotificationMessages = {
    'Do not disturb': [],
    'Clean my room': [
        { message: 'We received your request' },
        { message: 'We cannot fulfill your request at the moment' },
        { message: 'Your room is cleaned' },
    ],
    'Pick my laundry': [
        { message: 'We received your request' },
        { message: 'We cannot fulfill your request at the moment' },
        { message: 'Your laundry is ready' },
    ],
    'Call a doctor': [
        { message: 'We received your request' },
        { message: 'We cannot fulfill your request at the moment' },
        { message: "Couldn't find the doctor you requested" },
        { message: 'Doctor is coming' },
    ],
    'Wake me up': [
        { message: 'We received your request' },
        { message: 'We cannot fulfill your request at the moment' },
        { message: 'Your Wake me up request has been fulfilled' },
    ],
    'Call Transportation': [
        { message: 'We received your request' },
        { message: 'We cannot fulfill your request at the moment' },
        { message: 'Transportation arrived' },
        { message: 'Transportation will be here soon' },
    ],
    Emergency: [{ message: 'We received your request' }, { message: 'Help is coming' }],
    Massage: [
        { message: 'We received your request' },
        { message: 'We cannot fulfill your request at the moment' },
        { message: 'Thank you. We hope your experience was awesome' },
    ],
};

export enum OrderStatuses {
    received = 'Received',
    preparing = 'Preparing',
    completed = 'Completed',
    canceled = 'Canceled',
}

export const REFRESH_TIME_MS = 60000; // Refresh every one minute.

export const emptyUserInfo: UserInfoType = {
    name: '',
    email: '',
    roomsLimit: 0,
    subscriptionStart: '',
    subscriptionEnd: '',
    subscriptionType: '',
    token: '',
    uuid: '',
};
