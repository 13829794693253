import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import { TimePicker } from '@mui/x-date-pickers';
import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import equal from 'fast-deep-equal';
import React, { useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { OperationType, RestaurantFormType, RestaurantRequestType, RestaurantType } from '../model/data';
import { getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

interface Props {
    open: boolean;
    restaurant?: RestaurantType;
    handleOperation: (operation: OperationType, restaurant?: RestaurantType) => void;
}

function RestaurantForm(props: Props) {
    const { open, restaurant, handleOperation } = props;
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleError = useHandleError();

    const {
        control,
        formState: { errors },
        watch,
        setValue,
        getValues,
        reset,
        handleSubmit,
    } = useForm<Partial<RestaurantFormType>>();

    React.useEffect(() => {
        if (open) {
            reset();
            if (restaurant) {
                setValue('name', restaurant.name);
                setValue('allDay', Boolean(restaurant.allDay));
                setValue('openTime', restaurant.openTime ? dayjs(restaurant.openTime, 'HH:mm') : null);
                setValue('closeTime', restaurant.closeTime ? dayjs(restaurant.closeTime, 'HH:mm') : null);
                setValue('deliveryPoints', restaurant.deliveryPoints.split(','));
                setValue('operating', Boolean(restaurant.operating));
            } else {
                setValue('name', '');
                setValue('allDay', false);
                setValue('openTime', null);
                setValue('closeTime', null);
                setValue('deliveryPoints', []);
                setValue('operating', false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSubmit = (data: Partial<RestaurantFormType>) => {
        if (restaurant == null) {
            setIsSubmitting(true);

            const sendData: RestaurantRequestType = {
                ...data,
                allDay: Number(data.allDay),
                operating: Number(data.operating),
                openTime: data?.openTime?.format('HH:mm'),
                closeTime: data?.closeTime?.format('HH:mm'),
                deliveryPoints: data?.deliveryPoints?.join(),
                uuid: getUUID(),
            };

            axios
                .post('restaurants', sendData)
                .then((response: AxiosResponse<RestaurantType>) => {
                    setIsSubmitting(false);
                    handleOperation('ADD', response.data);
                })
                .catch((error: AxiosError) => {
                    setIsSubmitting(false);
                    handleError(error, 'Cannot save restaurant.');
                });
        } else {
            // Check if we changed anything
            let updatedData = {
                ...data,
                allDay: Number(data.allDay),
                openTime: data?.openTime?.format('HH:mm'),
                closeTime: data?.closeTime?.format('HH:mm'),
                deliveryPoints: data?.deliveryPoints?.join(),
                operating: Number(data.operating),
                id: restaurant.id,
            };

            if (!equal(updatedData, restaurant)) {
                setIsSubmitting(true);
                const sendData: RestaurantRequestType = {
                    ...updatedData,
                    uuid: getUUID(),
                };

                axios
                    .put('restaurants', sendData)
                    .then((response: AxiosResponse<RestaurantType>) => {
                        setIsSubmitting(false);
                        handleOperation('UPDATE', response.data);
                    })
                    .catch((error: AxiosError) => {
                        setIsSubmitting(false);
                        handleError(error, 'Cannot update restaurant.');
                    });
            }
            handleOperation('CLOSE', undefined);
        }
    };

    const deliveryPoints = ['Room', 'Beach', 'Pool'];

    const handleDelete = (restaurant: RestaurantType) => {
        setOpenDeleteDialog(false);
        axios
            .delete('restaurants', {
                params: {
                    uuid: getUUID(),
                    id: restaurant.id,
                },
            })
            .then(() => {
                handleOperation('DELETE', restaurant);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Cannot delete restaurant.');
            });
    };

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    const allDay = !!watch('allDay', getValues('allDay'));

    return (
        <>
            <Dialog
                onClose={(_event: object, reason: string) => {
                    if (reason !== 'backdropClick') {
                        handleOperation('CLOSE');
                    }
                }}
                open={open}>
                <DialogTitle>
                    {restaurant ? 'Edit Restaurant' : 'New Restaurant'}
                    <IconButton
                        aria-label="close"
                        onClick={() => handleOperation('CLOSE')}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: grey[500],
                        }}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <form id="restaurant-form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <Stack spacing={1}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        autoComplete="new-password"
                                        label="Name"
                                        required
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        error={!!errors.name}
                                        helperText={errors?.name?.message}
                                        margin="dense"
                                    />
                                )}
                            />

                            <Controller
                                name="allDay"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        {...field}
                                        sx={{ width: '100%' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onChange={(e) => field.onChange(e.target.checked)}
                                                checked={field.value}
                                            />
                                        }
                                        label="All Day Open"
                                        labelPlacement="end"
                                    />
                                )}
                            />

                            <Stack direction="row" spacing={1}>
                                <Controller
                                    name="openTime"
                                    control={control}
                                    rules={{
                                        validate: (value) => {
                                            if (allDay) {
                                                return true;
                                            } else {
                                                return value ? true : 'This field is required';
                                            }
                                        },
                                    }}
                                    render={({ field: { ref, ...rest } }) => (
                                        <TimePicker
                                            {...rest}
                                            label="Open Time"
                                            ampm={false}
                                            minutesStep={5}
                                            disabled={allDay}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    variant: 'outlined',
                                                    error: !!errors.openTime,
                                                    helperText: errors.openTime && (errors.openTime as FieldError).message,
                                                },
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="closeTime"
                                    control={control}
                                    rules={{
                                        validate: (value) => {
                                            if (allDay) {
                                                return true;
                                            } else {
                                                return value ? true : 'This field is required';
                                            }
                                        },
                                    }}
                                    render={({ field: { ref, ...rest } }) => (
                                        <TimePicker
                                            {...rest}
                                            label="Close Time"
                                            ampm={false}
                                            minutesStep={5}
                                            disabled={allDay}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    variant: 'outlined',
                                                    error: !!errors.closeTime,
                                                    helperText: errors.closeTime && (errors.closeTime as FieldError).message,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>

                            <Controller
                                name="deliveryPoints"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        autoComplete
                                        autoHighlight
                                        multiple
                                        size="small"
                                        id="deliveryPoints"
                                        options={deliveryPoints}
                                        getOptionLabel={(option) => option}
                                        filterSelectedOptions
                                        onChange={(_, data) => field.onChange(data)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                required
                                                margin="dense"
                                                error={!!errors.deliveryPoints}
                                                helperText={
                                                    errors.deliveryPoints && (errors.deliveryPoints as unknown as FieldError).message
                                                }
                                                label="Delivery Points"
                                            />
                                        )}
                                    />
                                )}
                            />

                            <Controller
                                name="operating"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        {...field}
                                        sx={{ width: '100%' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onChange={(e) => field.onChange(e.target.checked)}
                                                checked={field.value}
                                            />
                                        }
                                        label="Operating Status"
                                        labelPlacement="end"
                                    />
                                )}
                            />

                            {isSubmitting && <LinearProgress sx={{ mt: 1 }} />}
                        </Stack>
                    </form>
                </DialogContent>

                <DialogActions>
                    {restaurant != null ? (
                        <Stack sx={{ width: '100%' }} spacing={1} direction="row" justifyContent="space-between">
                            <Button variant="contained" color="secondary" onClick={() => setOpenDeleteDialog(true)} disabled={isSubmitting}>
                                Delete
                            </Button>
                            <Button form="restaurant-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                Update
                            </Button>
                        </Stack>
                    ) : (
                        <Button form="restaurant-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                            Create
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>{restaurant && `Are you sure you want to delete restaurant ${restaurant.name} ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you delete the restaurant, it will also delete all the menus that are connected to this restaurant.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>No</Button>
                    <Button onClick={() => restaurant && handleDelete(restaurant)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default RestaurantForm;
