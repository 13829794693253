import { Button, CircularProgress, Link, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import LoginForm from '../components/loginForm';
import poseidwn_logo from '../images/PoseidwnTech_logo_120.png';
import logo from '../images/hotelup.png';
import { loginState } from '../model/states';
import './login.css';

function Copyright(props: any) {
    return (
        <div className="copyright">
            <div className="copyright_items">
                <Typography variant="body2" color="textSecondary" align="center" {...props}>
                    {'Copyright © '}
                    <Link color="inherit" href="https://poseidwn.tech/" target="_blank">
                        Poseidwn Tech
                    </Link>
                </Typography>
                <img style={{ marginLeft: '5px' }} src={poseidwn_logo} alt="logo" width="32" height="32" />
            </div>
            <br />
            <Typography variant="body2" color="textSecondary" align="center" {...props}>
                <Button target="_top" color="primary" href={`mailto:hotelup@poseidwn.tech`}>
                    Support
                </Button>
            </Typography>
        </div>
    );
}

function Login() {
    const setLoggedIn = useSetRecoilState(loginState);
    const [isLoading, setLoading] = useState(true);

    React.useEffect(() => {
        axios
            .get('session')
            .then((_response) => {
                setLoading(false);
                setLoggedIn(true);
            })
            .catch((_error) => {
                setLoading(false);
                setLoggedIn(false);
            });
    }, [setLoggedIn]);

    return (
        <>
            <div className="login">
                <div className="right-login">
                    <div className="login-logo">
                        <img style={{ marginRight: '15px', borderRadius: '8px' }} src={logo} alt="logo" width="64" height="64" />
                        <div className="logo-title">
                            <span className="header">Hotel UP</span>
                            <span className="subheader">Services Made Simple</span>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="title-spinner">
                            <span className="header">Check for active session</span>
                            <CircularProgress />
                        </div>
                    ) : (
                        <LoginForm />
                    )}

                    <Copyright sx={{ mt: 1 }} />
                </div>
            </div>
        </>
    );
}

export default Login;
