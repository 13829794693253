import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Checkbox,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import equal from 'fast-deep-equal';
import React, { useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { ErrorMessages, OperationType, ReservationFormType, ReservationRequestType, ReservationType } from '../model/data';
import { generatePIN } from '../utils/helpers';
import { getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

interface Props {
    open: boolean;
    reservation?: ReservationType;
    handleOperation: (operation: OperationType, reservation?: ReservationType) => void;
}

function ReservationForm(props: Props) {
    const { open, reservation, handleOperation } = props;
    const theme = createTheme();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleError = useHandleError();
    const [openDialog, setOpenDialog] = React.useState(false);

    const {
        control,
        formState: { errors },
        watch,
        setValue,
        getValues,
        reset,
        handleSubmit,
    } = useForm<Partial<ReservationFormType>>();

    const titles = ['Mr', 'Mrs', 'Miss', 'Lady'];

    React.useEffect(() => {
        if (open) {
            reset();
            if (reservation?.checkIn) {
                setValue('customerTitle', reservation.customerTitle);
                setValue('customerName', reservation.customerName);
                setValue('customerEmail', reservation.customerEmail);
                setValue('earlyAccess', Boolean(reservation.earlyAccess));
                setValue('checkIn', dayjs(reservation.checkIn, 'YYYY-MM-DD'));
                setValue('checkOut', dayjs(reservation.checkOut, 'YYYY-MM-DD'));
                setValue('lockPIN', reservation.lockPIN);
            } else {
                setValue('customerTitle', '');
                setValue('customerName', '');
                setValue('customerEmail', '');
                setValue('earlyAccess', false);
                setValue('checkIn', null);
                setValue('checkOut', null);
                setValue('lockPIN', generatePIN());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSubmit = (data: Partial<ReservationFormType>) => {
        if (reservation?.checkIn) {
            // Check if we changed anything
            let updatedData = {
                ...reservation,
                ...data,
                checkIn: data?.checkIn?.format('YYYY-MM-DD'),
                checkOut: data?.checkOut?.format('YYYY-MM-DD'),
            };

            if (!equal(updatedData, reservation)) {
                setIsSubmitting(true);
                const sendData: ReservationRequestType = {
                    ...updatedData,
                    uuid: getUUID(),
                };

                axios
                    .put('rooms', sendData)
                    .then((response: AxiosResponse<ReservationType>) => {
                        setIsSubmitting(false);
                        handleOperation('UPDATE', response.data);
                    })
                    .catch((error: AxiosError<ErrorMessages>) => {
                        setIsSubmitting(false);
                        if (error.response?.status === 406 && error.response.data.errorType === 'Nuki error') {
                            handleOperation('CLOSE', undefined);
                            setOpenDialog(true);
                        } else {
                            handleError(error, 'Cannot update reservation. Try again later.');
                        }
                    });
            }
            handleOperation('CLOSE', undefined);
        } else {
            setIsSubmitting(true);

            const sendData: ReservationRequestType = {
                ...data,
                checkIn: data?.checkIn?.format('YYYY-MM-DD'),
                checkOut: data?.checkOut?.format('YYYY-MM-DD'),
                id: reservation?.id,
                uuid: getUUID(),
            };

            axios
                .post('checkin', sendData)
                .then((response: AxiosResponse<ReservationType>) => {
                    setIsSubmitting(false);
                    handleOperation('CHECK_IN', response.data);
                })
                .catch((error: AxiosError<ErrorMessages>) => {
                    setIsSubmitting(false);
                    if (error.response?.status === 406 && error.response.data.errorType === 'Nuki error') {
                        handleOperation('CLOSE', undefined);
                        setOpenDialog(true);
                    } else {
                        handleError(error, 'Cannot check in reservation. Try again later.');
                    }
                });
        }
    };

    const checkOut = watch('checkOut');
    const checkIn = watch('checkIn');

    const handleCheckOut = () => {
        setIsSubmitting(true);
        axios
            .post('checkout', {
                id: reservation?.id,
                uuid: getUUID(),
            })
            .then((response: AxiosResponse<ReservationType>) => {
                setIsSubmitting(false);
                handleOperation('CHECK_OUT', response.data);
            })
            .catch((error: AxiosError) => {
                setIsSubmitting(false);
                handleError(error, 'Cannot check out reservation.');
            });
    };

    const checkOutMinDate = () => {
        const today = dayjs();
        if (checkIn) {
            if (dayjs(checkIn, 'DD/MM/YYYY').isBefore(today)) {
                return today;
            } else {
                return checkIn;
            }
        } else {
            if (dayjs(getValues('checkIn'), 'DD/MM/YYYY').isBefore(today)) {
                return today;
            } else {
                return getValues('checkIn') ? getValues('checkIn') : today;
            }
        }
    };

    const lockPINLimits = ({ floatValue }: NumberFormatValues): boolean => {
        if (floatValue?.toString().includes('0')) {
            return false;
        }
        if (floatValue) {
            if (floatValue?.toString().startsWith('12')) {
                return false;
            }
            return floatValue >= 1 && floatValue <= 999999;
        }

        return true;
    };

    return (
        <>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Error setting PIN on locks</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Error occurred when trying to setup the PIN on the locks. If you want to proceed with the action you need to Disable
                        Lock option for the specific room from the Room Manager.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth={'xs'}
                onClose={(_event: object, reason: string) => {
                    if (reason !== 'backdropClick') {
                        handleOperation('CLOSE');
                    }
                }}
                open={open}>
                <DialogTitle>
                    Reservation Info
                    <IconButton
                        aria-label="close"
                        onClick={() => handleOperation('CLOSE')}
                        sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <form id="reservation-form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <Controller
                            name="customerTitle"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <FormControl sx={{ width: '100%' }} size="small" error={!!errors.customerTitle}>
                                    <InputLabel id="customer-title-label">Customer Title *</InputLabel>
                                    <Select {...field} id="customerTitle" labelId="customer-title-label" label="Customer Title *">
                                        {titles.map((title) => (
                                            <MenuItem key={title} value={title}>
                                                {title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.customerTitle && (
                                        <FormHelperText>{(errors.customerTitle as FieldError).message}</FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />

                        <Controller
                            name="customerName"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="customerName"
                                    autoComplete="off"
                                    label="Customer Name"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.customerName}
                                    helperText={errors?.customerName?.message}
                                    margin="dense"
                                />
                            )}
                        />

                        <Controller
                            name="customerEmail"
                            control={control}
                            rules={{
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please provide a valid mail',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="customerEmail"
                                    label="Customer Email"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="email"
                                    error={!!errors.customerEmail}
                                    helperText={errors?.customerEmail?.message}
                                    autoComplete="email"
                                    margin="dense"
                                />
                            )}
                        />

                        <Controller
                            name="earlyAccess"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    {...field}
                                    sx={{ width: '100%' }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            checked={field.value}
                                        />
                                    }
                                    label="Early Checkin"
                                    labelPlacement="end"
                                />
                            )}
                        />

                        <Stack sx={{ paddingTop: theme.spacing(1) }} direction="row" spacing={1}>
                            <Controller
                                name="checkIn"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field: { ref, ...rest } }) => (
                                    <DatePicker
                                        {...rest}
                                        label="Check In *"
                                        format="DD/MM/YYYY"
                                        maxDate={checkOut ? checkOut : getValues('checkOut') ? getValues('checkOut') : undefined}
                                        minDate={
                                            reservation?.checkIn
                                                ? dayjs(rest.value, 'DD/MM/YYYY').isAfter(dayjs())
                                                    ? dayjs()
                                                    : rest.value
                                                : dayjs()
                                        }
                                        slotProps={{
                                            textField: {
                                                helperText: errors.checkIn && (errors.checkIn as FieldError).message,
                                                error: !!errors.checkIn,
                                                size: 'small',
                                                variant: 'outlined',
                                            },
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                name="checkOut"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field: { ref, ...rest } }) => (
                                    <DatePicker
                                        {...rest}
                                        label="Check Out *"
                                        format="DD/MM/YYYY"
                                        minDate={checkOutMinDate()}
                                        slotProps={{
                                            textField: {
                                                helperText: errors.checkOut && (errors.checkOut as FieldError).message,
                                                error: !!errors.checkOut,
                                                size: 'small',
                                                variant: 'outlined',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                        {reservation?.roomLock ? (
                            <Controller
                                name="lockPIN"
                                control={control}
                                rules={{
                                    pattern: {
                                        value: /[0-9]{6}/,
                                        message: 'The PIN should be 6 digits number that does not start with 12 and not contain 0',
                                    },
                                    required: 'This field is required',
                                }}
                                render={({ field: { ref, ...props } }) => (
                                    <NumericFormat
                                        {...props}
                                        autoComplete="off"
                                        id="lockPIN"
                                        label="Lock PIN code *"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        error={!!errors.lockPIN}
                                        helperText={'The PIN should be 6 digits number that does not start with 12'}
                                        margin="dense"
                                        customInput={TextField}
                                        allowNegative={false}
                                        decimalScale={0}
                                        isAllowed={lockPINLimits}
                                    />
                                )}
                            />
                        ) : (
                            <></>
                        )}

                        {isSubmitting && <LinearProgress sx={{ marginTop: theme.spacing(1) }} />}
                    </form>
                </DialogContent>

                <DialogActions>
                    {reservation?.checkIn ? (
                        <Stack sx={{ width: '100%' }} spacing={1} direction="row" justifyContent="space-between">
                            <Button
                                form="reservation-form"
                                variant="contained"
                                color="secondary"
                                onClick={handleCheckOut}
                                disabled={isSubmitting}>
                                Check Out
                            </Button>
                            <Button form="reservation-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                Update
                            </Button>
                        </Stack>
                    ) : (
                        <Button form="reservation-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                            Check In
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ReservationForm;
