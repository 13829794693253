import AddIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Chip,
    createTheme,
    Grid,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderNavigation from '../components/headerNavigation';
import MenuForm from '../components/menuForm';
import RestaurantForm from '../components/restaurantForm';
import { MenuType, OperationType, RestaurantType } from '../model/data';
import { getRegistrationToken, getRestaurantNotifications, getUUID, storeRestaurantNotifications } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

function Restaurants() {
    const theme = createTheme();
    const history = useHistory();
    const [isRestaurantModalOpen, setIsRestaurantModalOpen] = useState(false);
    const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
    const [restaurant, setRestaurant] = useState<RestaurantType | undefined>();
    const [restaurants, setRestaurants] = useState<RestaurantType[]>([]);
    const [menus, setMenus] = useState<MenuType[]>([]);
    const [menu, setMenu] = useState<MenuType | undefined>();
    const [restaurantId, setRestaurantId] = useState<string | undefined>();

    const handleError = useHandleError();

    React.useEffect(() => {
        axios
            .get('restaurants', {
                params: {
                    uuid: getUUID(),
                },
            })
            .then((response: AxiosResponse<RestaurantType[]>) => {
                setRestaurants(response.data);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem fetching restaurants.');
            });

        axios
            .get('menu', {
                params: {
                    uuid: getUUID(),
                },
            })
            .then((response: AxiosResponse<MenuType[]>) => {
                setMenus(response.data);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem fetching menus.');
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNewRestaurant = () => {
        setRestaurant(undefined);
        setIsRestaurantModalOpen(true);
    };

    const editRestaurant = (restaurant: RestaurantType) => {
        setRestaurant(restaurant);
        setIsRestaurantModalOpen(true);
    };

    const handleRestaurantOperation = (operation: OperationType, data?: RestaurantType) => {
        switch (operation) {
            case 'ADD':
                if (data) {
                    setRestaurants([...restaurants, data]);

                    let restaurantNotifications = getRestaurantNotifications();
                    const restaurant = restaurantNotifications.find((restaurant) => restaurant.id.toString() === data.id.toString());
                    if (!restaurant) {
                        restaurantNotifications.push({ id: data.id, isChecked: true });
                        storeRestaurantNotifications(restaurantNotifications);
                        restaurantNotifications = getRestaurantNotifications();
                    }
                    const topic = `${getUUID()}_restaurant_${data.id}`;
                    axios
                        .post('subscribe', {
                            registration_token: getRegistrationToken(),
                            topic: topic,
                        })
                        .then(() => {
                            storeRestaurantNotifications(
                                restaurantNotifications.map((setting) =>
                                    setting.id.toString() === data.id.toString() ? { ...setting, isChecked: true } : setting
                                )
                            );
                        })
                        .catch((error: AxiosError) => {
                            handleError(error, 'Problem subscribe to restaurant notification');
                            storeRestaurantNotifications(
                                restaurantNotifications.map((setting) =>
                                    setting.id.toString() === data.id.toString() ? { ...setting, isChecked: false } : setting
                                )
                            );
                        });
                }
                break;
            case 'UPDATE':
                data && setRestaurants(restaurants.map((rest) => (rest.id === data.id ? data : rest)));
                break;
            case 'DELETE':
                if (data) {
                    setRestaurants(restaurants.filter((rest) => rest.id !== data.id));

                    let restaurantNotifications = getRestaurantNotifications();
                    const topic = `${getUUID()}_restaurant_${data.id}`;
                    axios
                        .post('unsubscribe', {
                            registration_token: getRegistrationToken(),
                            topic: topic,
                        })
                        .then(() => {
                            storeRestaurantNotifications(
                                restaurantNotifications.filter((rest) => rest.id.toString() !== data.id.toString())
                            );
                        })
                        .catch((error: AxiosError) => {
                            handleError(error, 'Problem unsubscribe from restaurant notification');
                        });
                }
                break;
            case 'CLOSE':
                break;
        }
        setIsRestaurantModalOpen(false);
    };

    const createNewMenu = (id: string) => {
        setRestaurantId(id);
        setMenu(undefined);
        setIsMenuModalOpen(true);
    };

    const editMenu = (menu: MenuType) => {
        setMenu(menu);
        setRestaurantId(undefined);
        setIsMenuModalOpen(true);
    };

    const handleMenuOperation = (operation: OperationType, data?: MenuType) => {
        switch (operation) {
            case 'ADD':
                if (data && data.active) {
                    // Remove active for the other menus
                    const tmpData = menus.map((item) =>
                        item.restaurantId === restaurantId
                            ? {
                                  ...item,
                                  active: false,
                              }
                            : item
                    );
                    setMenus([...tmpData, data]);
                } else {
                    data && setMenus([...menus, data]);
                }
                break;
            case 'UPDATE':
                if (data && data.active) {
                    // Remove active for the other menus
                    const tmpData = menus.map((menu) =>
                        menu.restaurantId === data.restaurantId
                            ? menu.id === data.id
                                ? data
                                : {
                                      ...menu,
                                      active: false,
                                  }
                            : menu
                    );
                    setMenus(tmpData);
                } else {
                    data && setMenus(menus.map((menu) => (menu.id === data.id ? data : menu)));
                }
                break;
            case 'DELETE':
                data && setMenus(menus.filter((menu) => menu.id !== data.id));
                break;
            case 'CLOSE':
                break;
        }
        setIsMenuModalOpen(false);
    };

    return (
        <>
            <HeaderNavigation />
            <Grid
                sx={{ width: '100%', paddingRight: theme.spacing(2), paddingLeft: theme.spacing(2) }}
                container
                spacing={1}
                justifyContent="space-between">
                <Grid item xs={12} sx={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start' }}>
                    {restaurants &&
                        restaurants.map((restaurant) => (
                            <Card
                                sx={{ width: 280, marginRight: theme.spacing(2), marginBottom: theme.spacing(2), minHeight: '350px' }}
                                key={restaurant.id}>
                                <CardActionArea onClick={() => editRestaurant(restaurant)}>
                                    <CardHeader
                                        title={restaurant.name}
                                        subheader={
                                            restaurant.allDay ? (
                                                <Typography variant="body1">All Day Open</Typography>
                                            ) : (
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography variant="body1">
                                                        Open <strong>{restaurant.openTime}</strong>
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        Close <strong>{restaurant.closeTime}</strong>
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                    />

                                    <CardContent>
                                        <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                                            <Grid item xs={7}>
                                                <Typography variant="body2">Operating Status</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                {restaurant.operating ? (
                                                    <CheckCircleIcon fontSize="small" style={{ color: green[500] }} />
                                                ) : (
                                                    <CancelIcon fontSize="small" style={{ color: red[500] }} />
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                                            <Grid item xs={7}>
                                                <Typography variant="body2">Delivery Points</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Stack alignItems="flex-start" spacing={1}>
                                                    {restaurant.deliveryPoints &&
                                                        restaurant.deliveryPoints
                                                            .split(',')
                                                            .map((point) => <Chip key={point} label={point} />)}
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                                <CardContent>
                                    <Stack justifyContent="flex-end" spacing={1}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                            <Typography variant="h5">Menus</Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => createNewMenu(restaurant.id)}>
                                                New Menu
                                            </Button>
                                        </Box>
                                        {menus &&
                                            menus
                                                .filter((menu) => restaurant.id === menu.restaurantId)
                                                .map((menu) => (
                                                    <Paper
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: grey[100],
                                                            },
                                                        }}
                                                        elevation={3}
                                                        key={menu.id}
                                                        onClick={() => editMenu(menu)}>
                                                        <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                                                            <Grid item xs={8}>
                                                                <Typography variant="h6">{menu.menuName}</Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end',
                                                                        marginTop: '1em',
                                                                        marginBottom: '1em',
                                                                    }}>
                                                                    {menu.active ? (
                                                                        <CheckCircleIcon fontSize="large" style={{ color: green[500] }} />
                                                                    ) : (
                                                                        <CancelIcon fontSize="large" style={{ color: red[500] }} />
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                                                            <Grid item xs={10}>
                                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => history.push(`/restaurants/menu/${menu.id}`)}>
                                                                        Menu Items
                                                                    </Button>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                ))}
                                    </Stack>
                                </CardContent>
                            </Card>
                        ))}
                    <Card sx={{ width: 280, marginRight: theme.spacing(2), marginBottom: theme.spacing(2), minHeight: '350px' }}>
                        <CardContent sx={{ height: '100%' }}>
                            <Grid container sx={{ height: '100%' }} spacing={1} alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Button onClick={createNewRestaurant} variant="contained" color="primary" startIcon={<AddIcon />}>
                                        New Restaurant
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <RestaurantForm open={isRestaurantModalOpen} restaurant={restaurant} handleOperation={handleRestaurantOperation} />
            {isMenuModalOpen && <MenuForm restaurantId={restaurantId} menu={menu} handleOperation={handleMenuOperation} />}
        </>
    );
}

export default Restaurants;
