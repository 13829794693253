import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHandleError } from '../utils/useHandleError';

interface Props {
    title: string;
    topic: string;
    handleClose: () => void;
}

export default function NotificationForm(props: Props) {
    const { title, topic, handleClose } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notificationContent, setNotificationContent] = React.useState('');
    const [notificationTitle, setNotificationTitle] = React.useState('');
    const handleError = useHandleError();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Dialog maxWidth={'sm'} onClose={handleClose} open={true}>
            <DialogTitle>
                {title}
                {handleClose ? (
                    <IconButton
                        aria-label="close"
                        sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                        onClick={handleClose}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    id="notificationTitle"
                    autoComplete="off"
                    label="Notification Title"
                    required
                    fullWidth
                    value={notificationTitle}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNotificationTitle(event.target.value);
                    }}
                    variant="outlined"
                    size="small"
                    margin="dense"
                />
                <TextField
                    id="notificationContent"
                    autoComplete="off"
                    label="Notification Content"
                    required
                    fullWidth
                    multiline
                    maxRows={4}
                    value={notificationContent}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNotificationContent(event.target.value);
                    }}
                    variant="outlined"
                    size="small"
                    margin="dense"
                />
                {isSubmitting && <LinearProgress sx={{ mt: 1 }} />}
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={notificationTitle === '' || notificationContent === '' || isSubmitting}
                    onClick={() => {
                        setIsSubmitting(true);
                        axios
                            .post('notify', {
                                title: notificationTitle,
                                body: notificationContent,
                                topic: topic.toString(),
                            })
                            .then(() => {
                                setIsSubmitting(false);
                                handleClose();
                                enqueueSnackbar('Notification sent successfully.', { variant: 'success' });
                            })
                            .catch((error: AxiosError) => {
                                setIsSubmitting(false);
                                handleError(error, 'Problem sending notification.');
                            });
                    }}>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
}
