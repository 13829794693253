import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    CircularProgress,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { ChangeEvent, useState } from 'react';
import HeaderNavigation from '../components/headerNavigation';
import empty from '../images/empty.png';
import { OrdersType, RestaurantType } from '../model/data';
import { OrderStatuses, REFRESH_TIME_MS } from '../utils/const';
import { getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

dayjs.extend(utc);

function Orders() {
    const theme = createTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState<OrdersType[]>([]);
    const [order, setOrder] = useState<OrdersType | undefined>();
    const [restaurants, setRestaurants] = useState<RestaurantType[]>([]);
    const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState(false);
    const [filterRestaurant, setFilterRestaurant] = useState('All');
    const [filterOrderStatus, setFilterOrderStatus] = useState('Received');
    const [filterOrderDate, setFilterOrderDate] = useState<Dayjs | null>(dayjs().subtract(1, 'day').startOf('day'));
    const [restaurantsNames, setRestaurantsNames] = useState(['All']);
    const handleError = useHandleError();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isChangingStatus, setIsChangingStatus] = useState(false);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleMessage = React.useCallback(
        (event) => {
            if (event.data.includes('New Order')) {
                setIsLoading(true);
                let restaurantId: string | undefined = undefined;
                if (filterRestaurant !== 'All') {
                    restaurantId = restaurants.find((rest) => rest.name === filterRestaurant)?.id;
                }

                axios
                    .post(
                        'orders',
                        {
                            uuid: getUUID(),
                            ...(restaurantId ? { restaurantId: restaurantId } : {}),
                        },
                        {
                            params: {
                                ...(filterOrderStatus === 'All' ? {} : { status: filterOrderStatus }),
                                fromDate: filterOrderDate?.utc().format('YYYY-MM-DD HH:mm'),
                            },
                        }
                    )
                    .then((response: AxiosResponse<OrdersType[]>) => {
                        setIsLoading(false);
                        setOrders(response.data);
                    })
                    .catch((error: AxiosError) => {
                        setIsLoading(false);
                        handleError(error, 'Problem fetching orders.');
                    });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterRestaurant]
    );

    React.useEffect(() => {
        navigator.serviceWorker.addEventListener('message', handleMessage);

        return () => navigator.serviceWorker.removeEventListener('message', handleMessage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleMessage]);

    React.useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            let restaurantId: string | undefined = undefined;
            if (filterRestaurant !== 'All') {
                restaurantId = restaurants.find((rest) => rest.name === filterRestaurant)?.id;
            }

            axios
                .post(
                    'orders',
                    {
                        uuid: getUUID(),
                        ...(restaurantId ? { restaurantId: restaurantId } : {}),
                    },
                    {
                        params: {
                            ...(filterOrderStatus === 'All' ? {} : { status: filterOrderStatus }),
                            fromDate: filterOrderDate?.utc().format('YYYY-MM-DD HH:mm'),
                        },
                    }
                )
                .then((response: AxiosResponse<OrdersType[]>) => {
                    setIsLoading(false);
                    setOrders(response.data);
                })
                .catch((error: AxiosError) => {
                    setIsLoading(false);
                    handleError(error, 'Problem fetching orders.');
                });
        };

        // Fetch orders first time we load the web page
        fetchData();
        setPage(0);

        // and then re-fetch orders every REFRESH_TIME_MS
        const interval = setInterval(() => {
            fetchData();
        }, REFRESH_TIME_MS);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRestaurant, filterOrderStatus, filterOrderDate]);

    React.useEffect(() => {
        axios
            .get('restaurants', {
                params: {
                    uuid: getUUID(),
                },
            })
            .then((response: AxiosResponse<RestaurantType[]>) => {
                setRestaurants(response.data);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem fetching restaurants.');
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        let data = ['All'];
        restaurants.forEach((rest) => {
            if (rest.operating) {
                data.push(rest.name);
            }
        });
        setRestaurantsNames(data);
        setFilterRestaurant('All');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurants]);

    const handleRestaurantChange = (_event: ChangeEvent<{}>, value: string) => {
        setFilterRestaurant(value);
    };

    const showOrderDetails = (order: OrdersType) => {
        setOrder(order);
        setIsOrderDetailsOpen(true);
    };

    const handleStatusChange = (_event: ChangeEvent<{}>, value: string) => {
        setFilterOrderStatus(value);
    };

    const handleDateChange = (date: Dayjs | null) => {
        date && setFilterOrderDate(date.startOf('day'));
    };

    const handleOrderPrint = (order: OrdersType) => {
        window.print();
        axios
            .put('orders', {
                uuid: getUUID(),
                orderId: order.id,
                orderStatus: 'Printed',
            })
            .then((response: AxiosResponse<OrdersType>) => {
                const newData = orders.map((ord) => (ord.id === response.data.id ? response.data : ord));
                setOrders(newData);
                setIsOrderDetailsOpen(false);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem changing order status.');
            });
    };

    const handleManualStatusChange = (value: string, order: OrdersType | undefined) => {
        if (order && order.orderStatus !== value) {
            setIsChangingStatus(true);
            axios
                .put('orders', {
                    uuid: getUUID(),
                    orderId: order.id,
                    orderStatus: value,
                })
                .then((response: AxiosResponse<OrdersType>) => {
                    const newData = orders.map((ord) => (ord.id === response.data.id ? response.data : ord));
                    setOrders(newData);
                    setIsOrderDetailsOpen(false);
                    setIsChangingStatus(false);
                })
                .catch((error: AxiosError) => {
                    handleError(error, 'Problem changing order status.');
                    setIsChangingStatus(false);
                });
        }
    };

    const filterOrderStatusChanges = (): string[] => {
        if (order && order.orderStatus === OrderStatuses.received) {
            return Object.values(OrderStatuses).filter(
                (e) => e === OrderStatuses.received || e === OrderStatuses.preparing || e === OrderStatuses.canceled
            );
        }

        if (order && order.orderStatus === OrderStatuses.preparing) {
            return Object.values(OrderStatuses).filter(
                (e) => e === OrderStatuses.preparing || e === OrderStatuses.completed || e === OrderStatuses.canceled
            );
        }

        if (order && order.orderStatus === OrderStatuses.canceled) {
            return [];
        }

        if (order && order.orderStatus === OrderStatuses.completed) {
            return [];
        }
        return [];
    };

    return (
        <>
            <HeaderNavigation />
            <Grid
                sx={{ width: '100%', paddingRight: theme.spacing(2), paddingLeft: theme.spacing(2) }}
                container
                spacing={1}
                justifyContent="space-between">
                <Grid item xs={10}>
                    <Paper style={{ width: '100%' }}>
                        <TableContainer component={Paper} style={{ maxHeight: 740 }}>
                            <Table stickyHeader aria-label="Reservations">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Hotel</TableCell>
                                        <TableCell>Restaurant</TableCell>
                                        <TableCell>Room</TableCell>
                                        <TableCell>Special Request</TableCell>
                                        <TableCell align="right">Total Price</TableCell>
                                        <TableCell>Delivery Point</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Items</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading ? (
                                        <TableRow key="loading">
                                            <TableCell colSpan={9} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    ) : orders && orders.length > 0 ? (
                                        orders
                                            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => (
                                                <TableRow
                                                    hover
                                                    key={row.id}
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => showOrderDetails(row)}>
                                                    <TableCell>{row.hotelName}</TableCell>
                                                    <TableCell>{row.restaurantName}</TableCell>
                                                    <TableCell>{row.roomName}</TableCell>
                                                    <TableCell>{row.orderSpecialRequest}</TableCell>
                                                    <TableCell align="right">{row.orderTotalPrice} &euro;</TableCell>
                                                    <TableCell>{row.deliveryPoint}</TableCell>
                                                    <TableCell>{row.orderStatus}</TableCell>
                                                    <TableCell>{row.orderItems.length}</TableCell>
                                                    <TableCell>{dayjs(row.createdAt).format('DD-MM-YYYY HH:mm')}</TableCell>
                                                </TableRow>
                                            ))
                                    ) : (
                                        <TableRow key="empty">
                                            <TableCell colSpan={9} align="center">
                                                <img style={{ borderRadius: '8px' }} src={empty} alt="empty" width="400" height="300" />
                                                <Typography variant="h4">No orders found</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={orders.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Paper sx={{ padding: theme.spacing(1) }}>
                        <Stack spacing={1}>
                            <Typography variant="h4">Filters</Typography>
                            <Autocomplete
                                autoComplete
                                autoHighlight
                                autoSelect
                                disableClearable
                                id="restaurantId"
                                onChange={handleRestaurantChange}
                                options={restaurantsNames}
                                value={filterRestaurant}
                                filterSelectedOptions
                                renderInput={(params) => <TextField {...params} margin="dense" variant="outlined" label="Restaurant" />}
                            />
                            <Autocomplete
                                autoComplete
                                autoHighlight
                                autoSelect
                                disableClearable
                                id="orderStatusId"
                                onChange={handleStatusChange}
                                options={['All', ...Object.values(OrderStatuses)]}
                                value={filterOrderStatus}
                                filterSelectedOptions
                                sx={{ paddingBottom: theme.spacing(1) }}
                                renderInput={(params) => <TextField {...params} margin="dense" variant="outlined" label="Order Status" />}
                            />
                            <DatePicker
                                label="Date"
                                format="DD/MM/YYYY"
                                value={filterOrderDate}
                                onChange={(value) => handleDateChange(value as Dayjs)}
                                maxDate={dayjs()}
                                slotProps={{
                                    textField: {
                                        id: 'date',
                                        margin: 'dense',
                                        variant: 'outlined',
                                    },
                                }}
                            />
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog maxWidth={'xs'} fullWidth onClose={() => setIsOrderDetailsOpen(false)} open={isOrderDetailsOpen}>
                <DialogTitle>
                    Order Info
                    {setIsOrderDetailsOpen ? (
                        <IconButton
                            aria-label="close"
                            sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                            onClick={() => setIsOrderDetailsOpen(false)}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </DialogTitle>
                <DialogContent>
                    {order && (
                        <table key={`details_${order.id}`}>
                            <tbody>
                                <tr>
                                    <td>Hotel</td>
                                    <td>{order.hotelName}</td>
                                </tr>
                                <tr>
                                    <td>Restaurant</td>
                                    <td>{order.restaurantName}</td>
                                </tr>
                                <tr>
                                    <td>Room</td>
                                    <td>{order.roomName}</td>
                                </tr>
                                <tr>
                                    <td>Special Request</td>
                                    <td>{order.orderSpecialRequest}</td>
                                </tr>
                                <tr>
                                    <td>Total Price</td>
                                    <td>{order.orderTotalPrice} &euro;</td>
                                </tr>
                                <tr>
                                    <td>Delivery Point</td>
                                    <td>{order.deliveryPoint}</td>
                                </tr>
                                <tr>
                                    <td>Date</td>
                                    <td>{dayjs(order.createdAt).format('DD-MM-YYYY HH:mm')}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Order Items</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Divider />
                                    </td>
                                </tr>
                                {order &&
                                    order.orderItems.map((item) => (
                                        <React.Fragment key={item.id}>
                                            <tr key={`name_${item.id}`}>
                                                <td>Name</td>
                                                <td>{item.name}</td>
                                            </tr>
                                            <tr key={`price_${item.id}`}>
                                                <td>Price</td>
                                                <td>{item.price} &euro;</td>
                                            </tr>
                                            <tr key={`quantity_${item.id}`}>
                                                <td>Quantity</td>
                                                <td>{item.quantity}</td>
                                            </tr>
                                            <tr key={`sr_${item.id}`}>
                                                <td>Special Request</td>
                                                <td>{item.specialRequest}</td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        </table>
                    )}
                </DialogContent>

                <DialogActions
                    sx={{
                        '@media print': {
                            display: 'none',
                        },
                    }}>
                    <Stack spacing={1} sx={{ width: '100%' }}>
                        {isChangingStatus && <LinearProgress sx={{ width: '100%', marginBottom: '16px' }} />}
                        <Stack spacing={1} direction="row" sx={{ width: '100%' }} justifyContent="space-between">
                            <Autocomplete
                                sx={{ width: '200px' }}
                                autoComplete
                                autoHighlight
                                fullWidth
                                disableClearable
                                disabled={isChangingStatus || (order && order.orderStatus === 'Canceled')}
                                size="small"
                                id="changeOrderStatus"
                                onChange={(_, value: string) => handleManualStatusChange(value, order)}
                                options={filterOrderStatusChanges()}
                                value={order && order.orderStatus}
                                filterSelectedOptions
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Order Status" />}
                            />
                            <Button
                                variant="contained"
                                disabled={isChangingStatus || (order && order.orderStatus === 'Canceled')}
                                color="primary"
                                onClick={() => order && handleOrderPrint(order)}>
                                Print
                            </Button>
                        </Stack>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Orders;
