import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Stack, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { AmenitiesEnum, AmenityOptionType, OperationType, RoomAmenities } from '../model/data';
import { amenitiesOptions } from '../utils/const';
import { getEnumKeyByEnumValue } from '../utils/helpers';
import { getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

const filter = createFilterOptions<AmenityOptionType>();

interface Props {
    amenity: AmenitiesEnum;
    roomAmenities: RoomAmenities;
    handleOperation: (operation: OperationType, roomAmenities?: RoomAmenities) => void;
}

export default function AmenityForm(props: Props) {
    const { amenity, roomAmenities, handleOperation } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [value, setValue] = React.useState<AmenityOptionType | null>(null);
    const handleError = useHandleError();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Dialog fullWidth maxWidth={'sm'} onClose={() => handleOperation('CLOSE')} open={true}>
            <DialogTitle>
                Update Status for {amenity}
                {handleOperation ? (
                    <IconButton
                        aria-label="close"
                        sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                        onClick={() => handleOperation('CLOSE')}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers>
                <Autocomplete
                    value={value}
                    onChange={(_event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                message: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                message: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                message: `Add "${params.inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="notification-message"
                    options={amenitiesOptions[amenity]}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.message;
                    }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} required label="Notification Message" variant="outlined" />}
                />
                {isSubmitting && <LinearProgress sx={{ mt: 1 }} />}
            </DialogContent>

            <DialogActions>
                <Stack sx={{ width: '100%' }} spacing={1} direction="row" justifyContent="space-between">
                    <Button
                        variant="outlined"
                        disabled={!value || isSubmitting}
                        onClick={() => {
                            setIsSubmitting(true);
                            axios
                                .post('notify', {
                                    title: `${amenity} - Status Updated`,
                                    body: value?.message,
                                    topic: roomAmenities.stayPass,
                                })
                                .then(() => {
                                    handleOperation('UPDATE');
                                    setIsSubmitting(false);
                                    enqueueSnackbar('Notification sent successfully.', { variant: 'success' });
                                })
                                .catch((error: AxiosError) => {
                                    setIsSubmitting(false);
                                    handleError(error, 'Problem sending notification.');
                                });
                        }}>
                        Update Status
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!value || isSubmitting}
                        onClick={() => {
                            setIsSubmitting(true);
                            axios
                                .post('notify', {
                                    title: `${amenity} - Completed`,
                                    body: value?.message,
                                    topic: roomAmenities.stayPass,
                                })
                                .then(() => {
                                    let amenityKey = getEnumKeyByEnumValue(AmenitiesEnum, amenity) as string;
                                    axios
                                        .put('amenities', {
                                            uuid: getUUID(),
                                            roomId: roomAmenities.id,
                                            amenities: {
                                                [amenityKey]: false,
                                            },
                                        })
                                        .then((response: AxiosResponse<RoomAmenities>) => {
                                            setIsSubmitting(false);
                                            handleOperation('DELETE', response.data);
                                        })
                                        .catch((error: AxiosError) => {
                                            setIsSubmitting(false);
                                            handleError(error, 'Problem update amenities.');
                                        });
                                    enqueueSnackbar('Notification sent successfully.', { variant: 'success' });
                                })
                                .catch((error: AxiosError) => {
                                    setIsSubmitting(false);
                                    handleError(error, 'Problem sending notification.');
                                });
                        }}>
                        Complete
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
