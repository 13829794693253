import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import { Avatar, Badge, Box, Button, createTheme, Grid, Paper, Stack, Tooltip } from '@mui/material';
import { yellow } from '@mui/material/colors';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../images/hotelup.png';
import { getUser } from '../utils/storageUtils';
import { useLogout } from '../utils/useLogout';
import NotificationSettingsForm from './notificationSettingsForm';
import ProfileForm from './profileForm';

function HeaderNavigation() {
    const history = useHistory();
    const theme = createTheme();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const logout = useLogout();
    const userInfo = getUser();
    const [amenityNotification, setAmenityNotification] = useState(false);
    const [orderNotification, setOrderNotification] = useState(false);

    React.useEffect(() => {
        navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data.includes('New Order')) {
                setOrderNotification(true);
            }
            if (event.data.includes('Amenity')) {
                setAmenityNotification(true);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (location.pathname.includes('orders')) {
            setOrderNotification(false);
        }
        if (location.pathname.includes('amenities')) {
            setAmenityNotification(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <>
            <Grid
                sx={{ width: '100%', padding: theme.spacing(2) }}
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between">
                <Grid item xs={12}>
                    <Paper sx={{ padding: theme.spacing(2) }} elevation={3}>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                            <Grid item xs={1}>
                                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                    <img style={{ borderRadius: '8px' }} src={logo} alt="logo" width="64" height="64" />
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Stack spacing={1} direction="row">
                                    <Button
                                        variant={location.pathname.includes('hotels') ? 'contained' : 'outlined'}
                                        onClick={() => history.push('/hotels')}>
                                        Hotels
                                    </Button>
                                    {/restaurant/i.test(userInfo.subscriptionType) && (
                                        <>
                                            <Button
                                                variant={location.pathname.includes('restaurants') ? 'contained' : 'outlined'}
                                                onClick={() => history.push('/restaurants')}>
                                                Restaurants
                                            </Button>
                                            {orderNotification ? (
                                                <Badge color="secondary" variant="dot">
                                                    <Button
                                                        variant={location.pathname.includes('orders') ? 'contained' : 'outlined'}
                                                        onClick={() => history.push('/orders')}>
                                                        Orders
                                                    </Button>
                                                </Badge>
                                            ) : (
                                                <Button
                                                    variant={location.pathname.includes('orders') ? 'contained' : 'outlined'}
                                                    onClick={() => history.push('/orders')}>
                                                    Orders
                                                </Button>
                                            )}
                                        </>
                                    )}
                                    {/amenities/i.test(userInfo.subscriptionType) && amenityNotification ? (
                                        <Badge color="secondary" variant="dot">
                                            <Button
                                                variant={location.pathname.includes('amenities') ? 'contained' : 'outlined'}
                                                onClick={() => history.push('/amenities')}>
                                                Amenities
                                            </Button>
                                        </Badge>
                                    ) : (
                                        <Button
                                            variant={location.pathname.includes('amenities') ? 'contained' : 'outlined'}
                                            onClick={() => history.push('/amenities')}>
                                            Amenities
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={2}>
                                <Stack spacing={1} direction="row" justifyContent="flex-end">
                                    <Tooltip sx={{ cursor: 'pointer' }} title="Edit notifications reception">
                                        <Avatar onClick={() => setOpenNotifications(true)}>
                                            <EditNotificationsIcon sx={{ color: yellow[500] }} />
                                        </Avatar>
                                    </Tooltip>
                                    <Tooltip sx={{ cursor: 'pointer' }} title={userInfo.name}>
                                        <Avatar alt={userInfo.name} onClick={() => setOpen(true)} />
                                    </Tooltip>
                                    <Button variant="contained" onClick={logout}>
                                        Sign out
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <ProfileForm open={open} handleClose={() => setOpen(false)} />
            <NotificationSettingsForm open={openNotifications} handleClose={() => setOpenNotifications(false)} />
        </>
    );
}

export default HeaderNavigation;
