import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { loginState } from '../model/states';
import { deleteUUID } from './storageUtils';

export function useLogout() {
    const setLoggedIn = useSetRecoilState(loginState);
    const history = useHistory();

    return () => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ';
        deleteUUID();
        setLoggedIn(false);
        history.push('/');
    };
}
