import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import equal from 'fast-deep-equal';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumberFormatValues, NumericFormat, PatternFormat } from 'react-number-format';
import { HotelFormType, HotelType, OperationType, RestaurantType, SupportedAmenitiesType } from '../model/data';
import { amenities } from '../utils/const';
import { getSupportedAmenities } from '../utils/helpers';
import { getUUID, getUser } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

interface Props {
    open: boolean;
    hotel?: HotelType;
    handleOperation: (operation: OperationType, hotel?: HotelType) => void;
}

function HotelForm(props: Props) {
    const { open, hotel, handleOperation } = props;
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleError = useHandleError();
    const [restaurants, setRestaurants] = useState<RestaurantType[]>([]);
    const userInfo = getUser();

    React.useEffect(() => {
        if (/restaurant/i.test(userInfo.subscriptionType)) {
            axios
                .get('restaurants', {
                    params: {
                        uuid: getUUID(),
                    },
                })
                .then((response: AxiosResponse<RestaurantType[]>) => {
                    setRestaurants(response.data);
                })
                .catch((error: AxiosError) => {
                    handleError(error, 'Problem fetching restaurants.');
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        control,
        setValue,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<HotelFormType>();

    React.useEffect(() => {
        if (open) {
            reset();
            setIsSubmitting(false);
            if (hotel) {
                setValue('hotelName', hotel.hotelName);
                setValue('hotelInfo', hotel.hotelInfo);
                setValue('hotelEmail', hotel.hotelEmail);
                setValue('hotelPhone', hotel.hotelPhone);
                setValue('wifiSsid', hotel.wifiSsid);
                setValue('wifiPass', hotel.wifiPass);
                setValue('latitude', hotel.latitude);
                setValue('longitude', hotel.longitude);
                setValue('rooms', hotel.rooms);
                setValue(
                    'restaurantsId',
                    hotel.restaurantsId
                        ? restaurants.filter((rest) => hotel.restaurantsId.split(',').map(Number).indexOf(Number(rest.id)) !== -1)
                        : []
                );
                setValue(
                    'amenities',
                    amenities.filter(
                        (amenity) =>
                            (Object.keys(hotel.amenities) as Array<keyof SupportedAmenitiesType>)
                                .filter((id) => hotel.amenities[id])
                                .indexOf(amenity.id) !== -1
                    )
                );
            } else {
                setValue('hotelName', '');
                setValue('hotelInfo', '');
                setValue('hotelEmail', '');
                setValue('hotelPhone', '');
                setValue('wifiSsid', '');
                setValue('wifiPass', '');
                setValue('latitude', null);
                setValue('longitude', null);
                setValue('rooms', null);
                setValue('restaurantsId', []);
                setValue('amenities', []);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSubmit = (data: HotelFormType) => {
        setIsSubmitting(true);
        const sendData = {
            ...data,
            amenities: getSupportedAmenities(data.amenities),
            restaurantsId: data.restaurantsId.map((rest) => rest.id).join(),
            uuid: getUUID(),
        };
        if (hotel == null) {
            axios
                .post('hotels', sendData)
                .then((response: AxiosResponse<HotelType>) => {
                    handleOperation('ADD', response.data);
                })
                .catch((error: AxiosError) => {
                    setIsSubmitting(false);
                    handleError(error, 'Cannot save hotel.');
                });
        } else {
            // Check if we changed anything
            let updatedData = {
                ...data,
                amenities: getSupportedAmenities(data.amenities),
                restaurantsId: data.restaurantsId.map((rest) => rest.id).join(),
                id: hotel.id,
            };
            if (!equal(updatedData, hotel)) {
                setIsSubmitting(true);
                const sendData = {
                    ...updatedData,
                    uuid: getUUID(),
                };

                axios
                    .put('hotels', sendData)
                    .then((response: AxiosResponse<HotelType>) => {
                        handleOperation('UPDATE', response.data);
                    })
                    .catch((error: AxiosError) => {
                        setIsSubmitting(false);
                        handleError(error, 'Cannot update hotel.');
                    });
            }
            handleOperation('CLOSE', undefined);
        }
    };

    const handleDelete = (hotel: HotelType) => {
        setIsSubmitting(true);
        setOpenDeleteDialog(false);
        axios
            .delete('hotels', {
                params: {
                    uuid: getUUID(),
                    id: hotel.id,
                },
            })
            .then(() => {
                handleOperation('DELETE', hotel);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Cannot delete hotel.');
            });
    };

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    const latitudeLimits = ({ floatValue }: NumberFormatValues): boolean => {
        if (floatValue) {
            return floatValue >= -90 && floatValue <= 90;
        }
        return true;
    };

    const longitudeLimits = ({ floatValue }: NumberFormatValues): boolean => {
        if (floatValue) {
            return floatValue >= -180 && floatValue <= 180;
        }
        return true;
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'xs'}
                onClose={(_event: object, reason: string) => {
                    if (reason !== 'backdropClick') {
                        handleOperation('CLOSE');
                    }
                }}
                open={open}>
                <DialogTitle>
                    {hotel ? 'Edit Hotel' : 'New Hotel'}
                    <IconButton
                        aria-label="close"
                        onClick={() => handleOperation('CLOSE')}
                        sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <form id="hotel-form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <Controller
                            name="hotelName"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="hotelName"
                                    label="Name"
                                    autoComplete="new-password"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.hotelName}
                                    helperText={errors?.hotelName?.message}
                                    margin="dense"
                                />
                            )}
                        />

                        <Controller
                            name="hotelInfo"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    autoComplete="off"
                                    id="hotelInfo"
                                    label="Info"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.hotelInfo}
                                    helperText={errors?.hotelInfo?.message}
                                    inputProps={{ maxLength: '100' }}
                                    margin="dense"
                                />
                            )}
                        />

                        <Controller
                            name="hotelEmail"
                            control={control}
                            rules={{
                                required: 'This field is required',
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please provide a valid mail',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    autoComplete="off"
                                    id="hotelEmail"
                                    label="Email"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.hotelEmail}
                                    helperText={errors?.hotelEmail?.message}
                                    margin="dense"
                                />
                            )}
                        />

                        <Controller
                            name="hotelPhone"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <MuiPhoneNumber
                                    {...field}
                                    sx={{
                                        svg: {
                                            height: '20px',
                                        },
                                    }}
                                    autoComplete="off"
                                    defaultCountry="gr"
                                    id="hotelPhone"
                                    label="Phone"
                                    required
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    error={!!errors.hotelPhone}
                                    helperText={errors?.hotelPhone?.message}
                                    margin="dense"
                                />
                            )}
                        />

                        <Controller
                            name="wifiSsid"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    autoComplete="off"
                                    id="wifiSsid"
                                    label="WiFi SSID"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.wifiSsid}
                                    helperText={errors?.wifiSsid?.message}
                                    margin="dense"
                                />
                            )}
                        />

                        <Controller
                            name="wifiPass"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    autoComplete="off"
                                    id="wifiPass"
                                    label="WiFi Password"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.wifiPass}
                                    helperText={errors?.wifiPass?.message}
                                    margin="dense"
                                />
                            )}
                        />

                        <Controller
                            name="latitude"
                            control={control}
                            render={({ field: { ref, ...props } }) => (
                                <NumericFormat
                                    {...props}
                                    autoComplete="off"
                                    id="latitude"
                                    label="Latitude"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.latitude}
                                    helperText={errors?.latitude?.message}
                                    margin="dense"
                                    customInput={TextField}
                                    allowNegative={true}
                                    decimalScale={5}
                                    isAllowed={latitudeLimits}
                                />
                            )}
                        />

                        <Controller
                            name="longitude"
                            control={control}
                            render={({ field: { ref, ...props } }) => (
                                <NumericFormat
                                    {...props}
                                    autoComplete="off"
                                    id="longitude"
                                    label="Longitude"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.longitude}
                                    helperText={errors?.longitude?.message}
                                    margin="dense"
                                    customInput={TextField}
                                    allowNegative={true}
                                    decimalScale={5}
                                    isAllowed={longitudeLimits}
                                />
                            )}
                        />

                        <Controller
                            name="rooms"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field: { ref, ...props } }) => (
                                <PatternFormat
                                    {...props}
                                    autoComplete="off"
                                    id="rooms"
                                    label="Rooms"
                                    required
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    error={!!errors.rooms}
                                    helperText={errors?.rooms?.message}
                                    margin="dense"
                                    format="####"
                                    customInput={TextField}
                                    disabled={!!hotel}
                                />
                            )}
                        />

                        {/restaurant/i.test(userInfo.subscriptionType) ? (
                            <Controller
                                name="restaurantsId"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        autoComplete
                                        autoHighlight
                                        multiple
                                        size="small"
                                        id="restaurantsId"
                                        onChange={(_, data) => field.onChange(data)}
                                        options={restaurants}
                                        getOptionLabel={(option) => option.name}
                                        isOptionEqualToValue={(option, value) => value === undefined || option.id === value.id}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField {...params} margin="dense" variant="outlined" label="Restaurants" />
                                        )}
                                    />
                                )}
                            />
                        ) : (
                            <></>
                        )}

                        <Controller
                            name="amenities"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    autoComplete
                                    autoHighlight
                                    multiple
                                    size="small"
                                    id="amenities"
                                    options={amenities}
                                    getOptionLabel={(option) => option.title}
                                    isOptionEqualToValue={(option, value) => value === undefined || option.id === value.id}
                                    onChange={(_, data) => field.onChange(data)}
                                    filterSelectedOptions
                                    renderInput={(params) => <TextField {...params} margin="dense" variant="outlined" label="Amenities" />}
                                />
                            )}
                        />

                        {isSubmitting && <LinearProgress sx={{ mt: 1 }} />}
                    </form>
                </DialogContent>

                <DialogActions>
                    {hotel != null ? (
                        <Stack sx={{ width: '100%' }} spacing={1} direction="row" justifyContent="space-between">
                            <Button variant="contained" color="secondary" onClick={() => setOpenDeleteDialog(true)} disabled={isSubmitting}>
                                Delete
                            </Button>
                            <Button form="hotel-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                Update
                            </Button>
                        </Stack>
                    ) : (
                        <Button form="hotel-form" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                            Create
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>{hotel && `Are you sure you want to delete hotel ${hotel.hotelName} ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you delete the hotel, it will also delete all the rooms that are connected to this hotel.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>No</Button>
                    <Button onClick={() => hotel && handleDelete(hotel)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default HotelForm;
