import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { ErrorMessages } from '../model/data';
import { useLogout } from './useLogout';

export const useHandleError = () => {
    const { enqueueSnackbar } = useSnackbar();
    const logout = useLogout();

    return (error: AxiosError, message?: string) => {
        if (error.response) {
            const errorMessage = error.response.data as ErrorMessages;
            if (error.response.status === 400 && errorMessage.error === 'Provided token is expired.') {
                enqueueSnackbar(errorMessage.error, { variant: 'error' });
                logout();
                return;
            }

            if (error.response.status === 403 && errorMessage.errorType === 'Permission error') {
                enqueueSnackbar(errorMessage.errorMessages, { variant: 'error' });
                return;
            }

            if (error.response.status === 404 && errorMessage.errorType === 'Rooms limit exceeded') {
                enqueueSnackbar(errorMessage.errorMessages, { variant: 'error' });
                return;
            }

            if (errorMessage.error) {
                enqueueSnackbar(errorMessage.error, { variant: 'error' });
                return;
            }

            if (errorMessage.errorMessage) {
                enqueueSnackbar(errorMessage.errorMessage, { variant: 'error' });
                return;
            }
        }
        message && enqueueSnackbar(message, { variant: 'error' });
    };
};
