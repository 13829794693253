import dayjs from 'dayjs';

export type UserInfoType = {
    name: string;
    email: string;
    roomsLimit: number;
    subscriptionStart: string;
    subscriptionEnd: string;
    subscriptionType: string;
    token: string;
    uuid: string;
};

export type RestaurantType = {
    id: string;
    name: string;
    openTime: string;
    closeTime: string;
    allDay: boolean;
    operating: boolean;
    deliveryPoints: string;
};

export type RestaurantRequestType = Partial<Omit<RestaurantType, 'allDay' | 'operating'>> & {
    allDay: number;
    operating: number;
    uuid: string | null;
};

export type RestaurantFormType = Omit<RestaurantType, 'id' | 'openTime' | 'closeTime' | 'deliveryPoints'> & {
    openTime: dayjs.Dayjs | null;
    closeTime: dayjs.Dayjs | null;
    deliveryPoints: string[];
};

export type HotelFormType = Omit<HotelType, 'id' | 'restaurantsId' | 'amenities' | 'latitude' | 'longitude' | 'rooms'> & {
    restaurantsId: RestaurantType[];
    amenities: AmenitiesType[];
    latitude: number | null;
    longitude: number | null;
    rooms: number | null;
};

export type MenuType = {
    id: string;
    restaurantId: string;
    menuName: string;
    active: boolean;
};

export type MenuItemType = {
    id: string;
    menuId: string;
    name: string;
    price: number;
    type: string;
    description: string;
    category: string;
    image: string;
};

export type MenuItemFormType = Omit<MenuItemType, 'id' | 'category'> & {
    category: string | null;
};

export type RoomType = {
    id: string;
    hotelId: string;
    roomName: string;
    roomActive: boolean;
    roomLock: boolean;
    unlatchMode: boolean;
    roomLockDeviceIds: string;
    roomLockToken: string;
};

export type RoomFormType = Omit<RoomType, 'id' | 'roomLockDeviceIds'> & {
    roomLockDeviceIds: LockDeviceType[];
};

export type LockDeviceType = {
    name: string;
    smartlockId: number;
};

export type ReservationType = RoomType & {
    customerName: string;
    customerTitle: string;
    customerEmail: string;
    checkIn: string;
    checkOut: string;
    stayPass: string;
    lockPIN: string;
    earlyAccess: boolean;
};

export type ReservationFormType = Omit<ReservationType, 'id' | 'checkIn' | 'checkOut'> & {
    checkIn: dayjs.Dayjs | null;
    checkOut: dayjs.Dayjs | null;
};

export type ReservationRequestType = Partial<ReservationType> & {
    uuid: string | null;
};

export type HotelType = {
    id: string;
    hotelName: string;
    hotelInfo: string;
    hotelLogo: string;
    hotelPhone: string;
    hotelEmail: string;
    wifiSsid: string;
    wifiPass: string;
    latitude: number;
    longitude: number;
    rooms: number;
    restaurantsId: string;
    amenities: SupportedAmenitiesType;
};

export enum AmenitiesEnum {
    dnd = 'Do not disturb',
    cleanRoom = 'Clean my room',
    laundry = 'Pick my laundry',
    doctorCall = 'Call a doctor',
    wakeUp = 'Wake me up',
    callTransportation = 'Call Transportation',
    emergency = 'Emergency',
    massage = 'Massage',
}

export type SupportedAmenitiesType = {
    -readonly [Property in keyof typeof AmenitiesEnum]: boolean;
};

export type AmenitiesType = {
    title: string;
    id: keyof typeof AmenitiesEnum;
};

type AmenitiesValueType = {
    value: boolean;
    data?: string;
};

export type AmenitiesReceivedType = {
    -readonly [key in keyof typeof AmenitiesEnum]: AmenitiesValueType;
};
export type RoomAmenities = RoomType & {
    amenities: AmenitiesReceivedType;
    stayPass: string;
};

export type OrdersType = {
    id: string;
    hotelName: string;
    restaurantName: string;
    roomName: string;
    orderSpecialRequest: string;
    orderTotalPrice: string;
    deliveryPoint: string;
    orderStatus: string;
    orderItems: OrderType[];
    createdAt: Date;
};

export type OrderType = {
    id: string;
    name: string;
    price: string;
    quantity: string;
    specialRequest: string;
};

export type OperationType = 'ADD' | 'UPDATE' | 'DELETE' | 'CLOSE' | 'CHECK_IN' | 'CHECK_OUT';

export interface AmenityOptionType {
    inputValue?: string;
    message: string;
}

export type AmenityChangedNotification = {
    roomName: string;
    amenities: Array<keyof AmenitiesReceivedType>;
};

export type AmenityNotificationMessages = {
    [key in AmenitiesEnum]: Array<AmenityOptionType>;
};

export type DynamicLink = {
    link: string;
};

export type ErrorMessages = {
    error: string;
    errorType: string;
    errorMessages: string;
    errorMessage: string;
};

export type HotelNotification = {
    id: string;
    isChecked: boolean;
};

export type RestaurantNotification = {
    id: string;
    isChecked: boolean;
};

export type LocalStorageData = {
    user: UserInfoType;
    restaurantNotifications: RestaurantNotification[];
    hotelNotifications: HotelNotification[];
};
