import {
    CircularProgress,
    createTheme,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import AmenityForm from '../components/amenityForm';
import HeaderNavigation from '../components/headerNavigation';
import empty from '../images/empty.png';
import { AmenitiesEnum, HotelType, OperationType, RoomAmenities } from '../model/data';
import { REFRESH_TIME_MS } from '../utils/const';
import { getActiveAmenities } from '../utils/helpers';
import { getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

function Amenities() {
    const theme = createTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [hotels, setHotels] = useState<HotelType[]>([]);
    const [amenities, setAmenities] = useState<RoomAmenities[]>([]);
    const [filterHotelId, setFilterHotelId] = useState('');
    const [isAmenityFormOpen, setIsAmenityFormOpen] = useState(false);
    const [amenity, setAmenity] = useState<AmenitiesEnum | undefined>();
    const [roomAmenities, setRoomAmenities] = useState<RoomAmenities | undefined>();
    const handleError = useHandleError();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleMessage = React.useCallback(
        (event) => {
            if (event.data.includes('Amenity')) {
                setIsLoading(true);
                axios
                    .post('amenities', {
                        uuid: getUUID(),
                        hotelId: filterHotelId,
                    })
                    .then((response: AxiosResponse<RoomAmenities[]>) => {
                        setIsLoading(false);
                        setAmenities(response.data);
                    })
                    .catch((error: AxiosError) => {
                        setIsLoading(false);
                        handleError(error, 'Problem fetching amenities.');
                    });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterHotelId]
    );

    React.useEffect(() => {
        navigator.serviceWorker.addEventListener('message', handleMessage);

        return () => navigator.serviceWorker.removeEventListener('message', handleMessage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleMessage]);

    React.useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            axios
                .post('amenities', {
                    uuid: getUUID(),
                    hotelId: filterHotelId,
                })
                .then((response: AxiosResponse<RoomAmenities[]>) => {
                    setIsLoading(false);
                    setAmenities(response.data);
                })
                .catch((error: AxiosError) => {
                    setIsLoading(false);
                    handleError(error, 'Problem fetching amenities.');
                });
        };

        // Fetch orders first time we load the web page
        if (filterHotelId !== '') {
            fetchData();
        }

        // and then re-fetch orders every REFRESH_TIME_MS
        const interval = setInterval(() => {
            fetchData();
        }, REFRESH_TIME_MS);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterHotelId]);

    React.useEffect(() => {
        axios
            .get('hotels', {
                params: {
                    uuid: getUUID(),
                },
            })
            .then((response: AxiosResponse<HotelType[]>) => {
                if (response.data.length > 0) {
                    setHotels(response.data);
                    setFilterHotelId(response.data[0].id);
                }
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem fetching hotels.');
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: SelectChangeEvent) => {
        setFilterHotelId(event.target.value as string);
    };

    const handleAmenityOperation = (operation: OperationType, data?: RoomAmenities) => {
        switch (operation) {
            case 'UPDATE':
                break;
            case 'DELETE':
                if (data) {
                    const newAmenities = amenities.map((amenity) => (amenity.id === data.id ? data : amenity));
                    setAmenities(newAmenities);
                }
                break;
            case 'CLOSE':
                break;
        }
        setIsAmenityFormOpen(false);
    };

    const handleSwitch = (row: RoomAmenities, amenity: AmenitiesEnum) => {
        setAmenity(amenity);
        setRoomAmenities(row);
        setIsAmenityFormOpen(true);
    };

    const activeAmenities = getActiveAmenities(amenities);
    return (
        <>
            <HeaderNavigation />
            <Grid
                sx={{ width: '100%', paddingRight: theme.spacing(2), paddingLeft: theme.spacing(2) }}
                container
                spacing={1}
                justifyContent="space-between">
                <Grid item xs={10}>
                    <Paper style={{ width: '100%' }}>
                        <TableContainer component={Paper} style={{ maxHeight: 740 }}>
                            <Table stickyHeader aria-label="Amenities">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Room Name</TableCell>
                                        {(Object.keys(AmenitiesEnum) as Array<keyof typeof AmenitiesEnum>).map((key) => (
                                            <TableCell key={key}>{AmenitiesEnum[key]}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading ? (
                                        <TableRow key="loading">
                                            <TableCell colSpan={Object.keys(AmenitiesEnum).length + 1} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    ) : activeAmenities && activeAmenities.length > 0 ? (
                                        activeAmenities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            <TableRow hover key={row.id}>
                                                <TableCell>{row.roomName}</TableCell>
                                                {(Object.keys(AmenitiesEnum) as Array<keyof typeof AmenitiesEnum>).map((key) => {
                                                    if (
                                                        key !== 'doctorCall' &&
                                                        key !== 'wakeUp' &&
                                                        key !== 'callTransportation' &&
                                                        key !== 'massage'
                                                    ) {
                                                        return (
                                                            <TableCell key={row.id + key}>
                                                                {row.amenities[key].value && (
                                                                    <Switch
                                                                        disabled={key === 'dnd'}
                                                                        checked={row.amenities[key].value}
                                                                        onChange={() => handleSwitch(row, AmenitiesEnum[key])}
                                                                        color="primary"
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return (
                                                            row.amenities[key] && (
                                                                <TableCell key={row.id + key}>
                                                                    {row.amenities[key].value && (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={row.amenities[key].value}
                                                                                    onChange={() => handleSwitch(row, AmenitiesEnum[key])}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={row.amenities[key].data}
                                                                        />
                                                                    )}
                                                                </TableCell>
                                                            )
                                                        );
                                                    }
                                                })}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow key="empty">
                                            <TableCell colSpan={9} align="center">
                                                <img style={{ borderRadius: '8px' }} src={empty} alt="empty" width="400" height="300" />
                                                <Typography variant="h4">No active amenities</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={activeAmenities.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Paper sx={{ padding: theme.spacing(1) }}>
                        <Stack spacing={1}>
                            <Typography variant="h4">Filters</Typography>
                            <FormControl variant="outlined">
                                <InputLabel id="hotel-filter-label">Hotel</InputLabel>
                                <Select
                                    labelId="hotel-filter-label"
                                    id="hotel-filter"
                                    value={filterHotelId}
                                    onChange={handleChange}
                                    label="Hotel">
                                    {hotels.map((row) => (
                                        <MenuItem key={row.id} value={row.id}>
                                            {row.hotelName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
            {isAmenityFormOpen && amenity && roomAmenities && (
                <AmenityForm handleOperation={handleAmenityOperation} amenity={amenity} roomAmenities={roomAmenities} />
            )}
        </>
    );
}

export default Amenities;
