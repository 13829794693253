import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Button,
    CircularProgress,
    createTheme,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderNavigation from '../components/headerNavigation';
import MenuItemForm from '../components/menuItemForm';
import { MenuItemType, MenuType, OperationType } from '../model/data';
import { API_URL } from '../utils/const';
import { getComparator, Order, stableSort } from '../utils/helpers';
import { getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

function Menu() {
    const theme = createTheme();
    const { id } = useParams<{ id: string }>();
    const [menu, setMenu] = useState<MenuType | undefined>();
    const [menuItems, setMenuItems] = useState<MenuItemType[]>([]);
    const [menuItem, setMenuItem] = useState<MenuItemType | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isMenuItemModalOpen, setIsMenuItemModalOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const handleError = useHandleError();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof MenuItemType>('category');

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
        axios
            .get('menu', {
                params: {
                    uuid: getUUID(),
                    id: id,
                },
            })
            .then((response: AxiosResponse<MenuType>) => {
                setMenu(response.data);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem fetching menu.');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setIsLoading(true);
        menu &&
            axios
                .get('menuitems', {
                    params: {
                        uuid: getUUID(),
                        menuId: menu.id,
                    },
                })
                .then((response: AxiosResponse<MenuItemType[]>) => {
                    setIsLoading(false);
                    setMenuItems(response.data);
                })
                .catch((error: AxiosError) => {
                    setIsLoading(false);
                    handleError(error, 'Problem fetching menu items.');
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu]);

    function editMenuItem(menuItem: MenuItemType) {
        setMenuItem(menuItem);
        setIsMenuItemModalOpen(true);
    }

    function deleteMenuItem(menuItem: MenuItemType) {
        axios
            .delete('menuitems', {
                params: {
                    uuid: getUUID(),
                    id: menuItem.id,
                },
            })
            .then(() => {
                setOpenDeleteDialog(false);
                setMenuItems(menuItems.filter((itm) => itm.id !== menuItem.id));
            })
            .catch((error: AxiosError) => {
                setOpenDeleteDialog(false);
                handleError(error, 'Cannot delete menuItem.');
            });
    }

    const createNewMenuItem = () => {
        setMenuItem(undefined);
        setIsMenuItemModalOpen(true);
    };

    const handleMenuItemOperation = (operation: OperationType, data?: MenuItemType) => {
        switch (operation) {
            case 'ADD':
                data && setMenuItems([...menuItems, data]);
                break;
            case 'UPDATE':
                data && setMenuItems(menuItems.map((itm) => (itm.id === data.id ? data : itm)));
                break;
            case 'CLOSE':
                break;
        }
        setIsMenuItemModalOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    const createSortHandler = (property: keyof MenuItemType) => (_event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <>
            <HeaderNavigation />

            <Grid
                sx={{ width: '100%', paddingRight: theme.spacing(2), paddingLeft: theme.spacing(2) }}
                container
                spacing={1}
                justifyContent="space-between">
                <Grid item xs={12}>
                    <Paper sx={{ padding: theme.spacing(2) }}>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h3">Menu Items for {menu?.menuName}</Typography>
                            </Grid>
                            <Grid item>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="contained" color="primary" onClick={createNewMenuItem}>
                                        New Menu Item
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
                            <Table stickyHeader aria-label="Menu Items">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        <TableCell align="left">
                                            <TableSortLabel
                                                active={orderBy === 'name'}
                                                direction={orderBy === 'name' ? order : 'asc'}
                                                onClick={createSortHandler('name')}>
                                                Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="left">Description</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="left">
                                            <TableSortLabel
                                                active={orderBy === 'category'}
                                                direction={orderBy === 'category' ? order : 'asc'}
                                                onClick={createSortHandler('category')}>
                                                Category
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="left">Special Notes</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading ? (
                                        <TableRow key="loading">
                                            <TableCell colSpan={7} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        menuItems &&
                                        stableSort(menuItems, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row">
                                                        {row.image && <img alt="menu" src={API_URL + row.image} width="120" height="90" />}
                                                    </TableCell>
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell align="left">{row.description}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }} align="right">
                                                        {row.price} &euro;
                                                    </TableCell>
                                                    <TableCell align="left">{row.category}</TableCell>
                                                    <TableCell align="left">{row.type}</TableCell>
                                                    <TableCell align="center">
                                                        <Stack spacing={1} justifyContent="center" direction="row">
                                                            <IconButton
                                                                color="secondary"
                                                                onClick={() => {
                                                                    setMenuItem(row);
                                                                    setOpenDeleteDialog(true);
                                                                }}
                                                                aria-label="delete menu item"
                                                                size="large">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => editMenuItem(row)}
                                                                aria-label="edit menu item"
                                                                size="large">
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={menuItems.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {isMenuItemModalOpen && <MenuItemForm menuId={menu && menu.id} menuItem={menuItem} handleOperation={handleMenuItemOperation} />}
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>{menuItem && `Are you sure you want to delete menu item ${menuItem.name} ?`}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>No</Button>
                    <Button onClick={() => menuItem && deleteMenuItem(menuItem)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Menu;
