import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton, Switch, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { HotelNotification, HotelType, RestaurantNotification, RestaurantType } from '../model/data';
import {
    getHotelNotifications,
    getRegistrationToken,
    getRestaurantNotifications,
    getUUID,
    getUser,
    storeHotelNotifications,
    storeRestaurantNotifications,
} from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';
interface Props {
    open: boolean;
    handleClose: () => void;
}

export default function NotificationSettingsForm(props: Props) {
    const { open, handleClose } = props;
    const userInfo = getUser();
    const [hotels, setHotels] = useState<HotelType[]>([]);
    const handleError = useHandleError();
    const [restaurants, setRestaurants] = useState<RestaurantType[]>([]);

    const [hotelSettings, setHotelSettings] = useState<HotelNotification[]>(getHotelNotifications());
    const [restaurantSettings, setRestaurantSettings] = useState<RestaurantNotification[]>(getRestaurantNotifications());

    React.useEffect(() => {
        if (open) {
            axios
                .get('hotels', {
                    params: {
                        uuid: getUUID(),
                    },
                })
                .then((response: AxiosResponse<HotelType[]>) => {
                    setHotels(response.data);
                })
                .catch((error: AxiosError) => {
                    handleError(error, 'Problem fetching hotels.');
                });
            setHotelSettings(getHotelNotifications());

            if (/restaurant/i.test(userInfo.subscriptionType)) {
                axios
                    .get('restaurants', {
                        params: {
                            uuid: getUUID(),
                        },
                    })
                    .then((response: AxiosResponse<RestaurantType[]>) => {
                        setRestaurants(response.data);
                    })
                    .catch((error: AxiosError) => {
                        handleError(error, 'Problem fetching restaurants.');
                    });
                setRestaurantSettings(getRestaurantNotifications());
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    React.useEffect(() => {
        storeHotelNotifications(hotelSettings);
    }, [hotelSettings]);

    React.useEffect(() => {
        storeRestaurantNotifications(restaurantSettings);
    }, [restaurantSettings]);

    const handleHotelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const topic = `${getUUID()}_hotel_${event.target.name}`;
        if (event.target.checked) {
            console.log('Subscribe to topic: ', topic);
            axios
                .post('subscribe', {
                    registration_token: getRegistrationToken(),
                    topic: topic,
                })
                .then(() => {
                    setHotelSettings(
                        hotelSettings.map((setting) =>
                            setting.id.toString() === event.target.name.toString() ? { ...setting, isChecked: true } : setting
                        )
                    );
                })
                .catch((error: AxiosError) => {
                    handleError(error, 'Problem subscribe to hotel notification');
                });
        } else {
            console.log('Unsubscribe from topic: ', topic);
            axios
                .post('unsubscribe', {
                    registration_token: getRegistrationToken(),
                    topic: topic,
                })
                .then(() => {
                    setHotelSettings(
                        hotelSettings.map((setting) =>
                            setting.id.toString() === event.target.name.toString() ? { ...setting, isChecked: false } : setting
                        )
                    );
                })
                .catch((error: AxiosError) => {
                    handleError(error, 'Problem unsubscribe from hotel notification');
                });
        }
    };

    const handleRestaurantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const topic = `${getUUID()}_restaurant_${event.target.name}`;
        if (event.target.checked) {
            console.log('Subscribe to topic: ', topic);
            axios
                .post('subscribe', {
                    registration_token: getRegistrationToken(),
                    topic: topic,
                })
                .then(() => {
                    setRestaurantSettings(
                        restaurantSettings.map((setting) =>
                            setting.id.toString() === event.target.name.toString() ? { ...setting, isChecked: true } : setting
                        )
                    );
                })
                .catch((error: AxiosError) => {
                    handleError(error, 'Problem subscribe to restaurant notification');
                });
        } else {
            console.log('Unsubscribe from topic: ', topic);
            axios
                .post('unsubscribe', {
                    registration_token: getRegistrationToken(),
                    topic: topic,
                })
                .then(() => {
                    setRestaurantSettings(
                        restaurantSettings.map((setting) =>
                            setting.id.toString() === event.target.name.toString() ? { ...setting, isChecked: false } : setting
                        )
                    );
                })
                .catch((error: AxiosError) => {
                    handleError(error, 'Problem unsubscribe from restaurant notification');
                });
        }
    };

    return (
        <Dialog fullWidth onClose={handleClose} open={open}>
            <DialogTitle>
                Notification Settings
                {handleClose ? (
                    <IconButton
                        aria-label="close"
                        sx={{ position: 'absolute', right: 8, top: 8, color: grey[500] }}
                        onClick={handleClose}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="h5" gutterBottom>
                    Hotels
                </Typography>
                <FormGroup>
                    {hotels.map((hotel) => (
                        <FormControlLabel
                            key={hotel.id}
                            control={
                                <Switch
                                    name={hotel.id.toString()}
                                    onChange={handleHotelChange}
                                    checked={
                                        hotelSettings.find((hotelNotification) => hotelNotification.id.toString() === hotel.id.toString())
                                            ?.isChecked
                                    }
                                    color="primary"
                                />
                            }
                            label={hotel.hotelName}
                            labelPlacement="end"
                        />
                    ))}
                </FormGroup>
                {/restaurant/i.test(userInfo.subscriptionType) ? (
                    <>
                        <Typography variant="h5" gutterBottom>
                            Restaurants
                        </Typography>
                        <FormGroup>
                            {restaurants.map((restaurant) => (
                                <FormControlLabel
                                    key={restaurant.id}
                                    control={
                                        <Switch
                                            name={restaurant.id.toString()}
                                            onChange={handleRestaurantChange}
                                            checked={
                                                restaurantSettings.find(
                                                    (restaurantNotification) =>
                                                        restaurantNotification.id.toString() === restaurant.id.toString()
                                                )?.isChecked
                                            }
                                            color="primary"
                                        />
                                    }
                                    label={restaurant.name}
                                    labelPlacement="end"
                                />
                            ))}
                        </FormGroup>
                    </>
                ) : (
                    <></>
                )}
            </DialogContent>
        </Dialog>
    );
}
