import axios, { AxiosError, AxiosResponse } from 'axios';
import logo from '../images/hotelup.png';
import {
    AmenitiesEnum,
    AmenitiesReceivedType,
    AmenitiesType,
    AmenityChangedNotification,
    HotelNotification,
    HotelType,
    RestaurantNotification,
    RestaurantType,
    RoomAmenities,
    SupportedAmenitiesType,
} from '../model/data';
import { snackBarUtils } from './SnackBarUtils';
import {
    getHotelNotifications,
    getRegistrationToken,
    getRestaurantNotifications,
    getUUID,
    getUser,
    storeHotelNotifications,
    storeRestaurantNotifications,
} from './storageUtils';

export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null => {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
};

export const getEnumValuesArray = (keys: Array<keyof AmenitiesReceivedType>): Array<string> => {
    return keys.map((key) => AmenitiesEnum[key]);
};

export function askNotificationPermission() {
    // function to actually ask the permissions
    function handlePermission(_permission: string) {
        if (Notification.permission === 'denied' || Notification.permission === 'default') {
            alert('Please enable notification to receive notice about amenities and orders.');
        }
    }

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
        console.log('This browser does not support notifications.');
    } else {
        if (checkNotificationPromise()) {
            Notification.requestPermission().then((permission) => {
                handlePermission(permission);
            });
        } else {
            Notification.requestPermission(function (permission) {
                handlePermission(permission);
            });
        }
    }
}

function checkNotificationPromise() {
    try {
        Notification.requestPermission().then();
    } catch (e) {
        return false;
    }

    return true;
}

export function diff(o1: AmenitiesReceivedType, o2: AmenitiesReceivedType): Array<keyof AmenitiesReceivedType> {
    return (Object.keys(o1) as Array<keyof AmenitiesReceivedType>).filter((k) => o1[k].value !== o2[k].value);
}

export const findChanges = (prevAmenities: RoomAmenities[], newAmenities: RoomAmenities[]): AmenityChangedNotification[] => {
    let amenitiesChanged: AmenityChangedNotification[] = [];

    if (prevAmenities.length > 0) {
        newAmenities.forEach((value, index) => {
            let changes = diff(value.amenities, prevAmenities[index].amenities);

            if (changes.length > 0) {
                amenitiesChanged.push({
                    roomName: value.roomName,
                    amenities: changes,
                });
            }
        });
    }
    return amenitiesChanged;
};

export const sendNotification = (title: string, body: string) => {
    if (Notification.permission === 'denied' || Notification.permission === 'default') {
        askNotificationPermission();
    } else {
        new Notification(title, {
            body: body,
            icon: logo,
        });
    }
};

export const getSupportedAmenities = (amenities: AmenitiesType[]): SupportedAmenitiesType => {
    let supportedAmenities: SupportedAmenitiesType = {
        dnd: false,
        cleanRoom: false,
        doctorCall: false,
        laundry: false,
        wakeUp: false,
        callTransportation: false,
        emergency: false,
        massage: false,
    };
    amenities.map((amenity) => amenity.id).forEach((id) => (supportedAmenities[id] = true));
    return supportedAmenities;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function getActiveAmenities(roomAmenities: RoomAmenities[]): RoomAmenities[] {
    return roomAmenities.filter((room) => hasActiveAmenity(room.amenities));
}

function hasActiveAmenity(amenities: AmenitiesReceivedType) {
    return (
        amenities.callTransportation.value ||
        amenities.cleanRoom.value ||
        amenities.dnd.value ||
        amenities.doctorCall.value ||
        amenities.emergency.value ||
        amenities.laundry.value ||
        amenities.massage.value ||
        amenities.wakeUp.value
    );
}

export function generatePIN(length: number = 6): string {
    const min = 1;
    const max = 9;
    let pin: Array<number> = [];
    let p: number;
    for (let index = 0; index < length; index++) {
        p = Math.floor(Math.random() * (max - min + 1)) + min;
        if (index === 1 && pin[0] === 1) {
            while (p === 2) {
                p = Math.floor(Math.random() * (max - min + 1)) + min;
            }
        }
        pin.push(p);
    }

    pin.toString = function () {
        return this.join('');
    };
    return pin.toString();
}

export function resubscribeToNotificationSettings() {
    const hotelNotifications = getHotelNotifications();

    hotelNotifications.forEach((notification) => {
        const topic = `${getUUID()}_hotel_${notification.id}`;
        if (notification.isChecked) {
            console.log('Subscribe to topic: ', topic);
            axios
                .post('subscribe', {
                    registration_token: getRegistrationToken(),
                    topic: topic,
                })
                .catch((_error: AxiosError) => {
                    snackBarUtils.error('Problem subscribe to hotel notification');
                });
        } else {
            console.log('Unsubscribe from topic: ', topic);
            axios
                .post('unsubscribe', {
                    registration_token: getRegistrationToken(),
                    topic: topic,
                })
                .catch((_error: AxiosError) => {
                    snackBarUtils.error('Problem unsubscribe from hotel notification');
                });
        }
    });

    const userInfo = getUser();
    if (/restaurant/i.test(userInfo.subscriptionType)) {
        const restaurantNotifications = getRestaurantNotifications();

        restaurantNotifications.forEach((notification) => {
            const topic = `${getUUID()}_restaurant_${notification.id}`;
            if (notification.isChecked) {
                console.log('Subscribe to topic: ', topic);
                axios
                    .post('subscribe', {
                        registration_token: getRegistrationToken(),
                        topic: topic,
                    })
                    .catch((_error: AxiosError) => {
                        snackBarUtils.error('Problem subscribe to restaurant notification');
                    });
            } else {
                console.log('Unsubscribe from topic: ', topic);
                axios
                    .post('unsubscribe', {
                        registration_token: getRegistrationToken(),
                        topic: topic,
                    })
                    .catch((_error: AxiosError) => {
                        snackBarUtils.error('Problem unsubscribe from restaurant notification');
                    });
            }
        });
    }
}

export function isNotificationSettingsSet(): boolean {
    const userInfo = getUser();
    if (/restaurant/i.test(userInfo.subscriptionType)) {
        return getHotelNotifications().length > 0 && getRestaurantNotifications().length > 0;
    } else {
        return getHotelNotifications().length > 0;
    }
}

export function initializeNotificationSettings() {
    const userInfo = getUser();

    let hotelNotifications: HotelNotification[] = [];
    axios
        .get('hotels', {
            params: {
                uuid: getUUID(),
            },
        })
        .then((response: AxiosResponse<HotelType[]>) => {
            response.data.forEach((hotel) => {
                hotelNotifications.push({ id: hotel.id, isChecked: true });
            });
            storeHotelNotifications(hotelNotifications);
        })
        .catch((error: AxiosError) => {
            snackBarUtils.error('Problem fetching hotels for initial setting notifications.');
        });

    if (/restaurant/i.test(userInfo.subscriptionType)) {
        let restaurantNotifications: RestaurantNotification[] = [];
        axios
            .get('restaurants', {
                params: {
                    uuid: getUUID(),
                },
            })
            .then((response: AxiosResponse<RestaurantType[]>) => {
                response.data.forEach((restaurant) => {
                    restaurantNotifications.push({ id: restaurant.id, isChecked: true });
                });
                storeRestaurantNotifications(restaurantNotifications);
            })
            .catch((error: AxiosError) => {
                snackBarUtils.error('Problem fetching restaurants for initial setting notifications.');
            });
    }
}
