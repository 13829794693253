import { LoadingButton } from '@mui/lab';
import { Container, createTheme, TextField } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { UserInfoType } from '../model/data';
import { loginState } from '../model/states';
import { storeUser } from '../utils/storageUtils';

function LoginForm() {
    const setLoggedIn = useSetRecoilState(loginState);
    const { enqueueSnackbar } = useSnackbar();
    const theme = createTheme();
    const [isSubmitting, setIsSubmitting] = useState(false);

    interface IFormInput {
        email: string;
        password: string;
    }

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<IFormInput>({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const onSubmit = (data: IFormInput) => {
        setIsSubmitting(true);
        axios
            .post('login', data)
            .then((response: AxiosResponse<UserInfoType>) => {
                storeUser(response.data);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                setIsSubmitting(false);
                setLoggedIn(true);
            })
            .catch((_error) => {
                setIsSubmitting(false);
                enqueueSnackbar('Login error. Please check your email or password.', { variant: 'error' });
            });
    };

    return (
        <Container
            sx={{
                display: 'flex',
                width: '400px',
                maxWidth: '100%',
                margin: '0 auto',
                flexDirection: 'column',
            }}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please provide a valid mail',
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id="email"
                            label="Email"
                            fullWidth
                            variant="outlined"
                            size="small"
                            type="email"
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                            autoComplete="email"
                            margin="dense"
                        />
                    )}
                />

                <Controller
                    name="password"
                    control={control}
                    rules={{
                        required: 'This field is required',
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id="password"
                            label="Password"
                            fullWidth
                            variant="outlined"
                            size="small"
                            type="password"
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            margin="dense"
                        />
                    )}
                />

                <LoadingButton
                    sx={{ marginTop: theme.spacing(1) }}
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary">
                    Sign In
                </LoadingButton>
            </form>
        </Container>
    );
}

export default LoginForm;
