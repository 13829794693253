import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { SnackbarUtilsConfigurator } from './utils/SnackBarUtils';

const theme = createTheme({
    components: {
        MuiDesktopTimePicker: {
            defaultProps: {
                viewRenderers: {
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                },
            },
        },
        MuiDesktopDateTimePicker: {
            defaultProps: {
                viewRenderers: {
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                },
            },
        },
    },
});

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <RecoilRoot>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <SnackbarProvider maxSnack={1}>
                        <SnackbarUtilsConfigurator />
                        <App />
                    </SnackbarProvider>
                </LocalizationProvider>
            </RecoilRoot>
        </ThemeProvider>
    </StyledEngineProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
