import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Box,
    Button,
    CircularProgress,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { useHistory, useParams } from 'react-router-dom';
import HeaderNavigation from '../components/headerNavigation';
import NotificationForm from '../components/notificationForm';
import ReservationForm from '../components/reservationForm';
import { DynamicLink, HotelType, OperationType, ReservationType } from '../model/data';
import { ENVIRONMENT } from '../utils/const';
import { getUser, getUUID } from '../utils/storageUtils';
import { useHandleError } from '../utils/useHandleError';

const SEPARATOR = '$';

function Reservations() {
    const theme = createTheme();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [hotel, setHotel] = useState<HotelType | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isQRCodeOpen, setIsQRCodeOpen] = useState(false);
    const [isNotifyOpen, setIsNotifyOpen] = useState(false);
    const [notifyTitle, setNotifyTitle] = useState('');
    const [notifyTopic, setNotifyTopic] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [reservations, setReservations] = useState<ReservationType[]>([]);
    const [reservation, setReservation] = useState<ReservationType | undefined>();
    const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
    const handleError = useHandleError();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const userInfo = getUser();

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
        setIsLoading(true);
        hotel &&
            axios
                .get('rooms', {
                    params: {
                        uuid: getUUID(),
                        hotelId: id,
                    },
                })
                .then((response: AxiosResponse<ReservationType[]>) => {
                    setIsLoading(false);
                    setReservations(response.data);
                })
                .catch((error: AxiosError) => {
                    setIsLoading(false);
                    handleError(error, 'Problem fetching rooms.');
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotel]);

    React.useEffect(() => {
        axios
            .get('hotels', {
                params: {
                    uuid: getUUID(),
                    id: id,
                },
            })
            .then((response: AxiosResponse<HotelType>) => {
                setHotel(response.data);
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem fetching hotel.');
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editReservation = (reservation: ReservationType) => {
        setReservation(reservation);
        setIsReservationModalOpen(true);
    };

    const handleReservationOperation = (operation: OperationType, data?: ReservationType) => {
        switch (operation) {
            case 'CHECK_IN':
            case 'CHECK_OUT':
            case 'UPDATE':
                if (data) {
                    const newData = reservations.map((res) => (res.id === data?.id ? data : res));
                    setReservations(newData);
                }
                break;
            case 'CLOSE':
                break;
        }
        setIsReservationModalOpen(false);
    };

    const showQRCode = (row: ReservationType) => {
        if (ENVIRONMENT === 'prod') {
            setQrCode(`${row.hotelId}${SEPARATOR}${row.roomName}${SEPARATOR}${row.stayPass}`);
        } else {
            setQrCode(`${row.hotelId}${SEPARATOR}${row.roomName}${SEPARATOR}${row.stayPass}${SEPARATOR}${ENVIRONMENT}`);
        }

        setIsQRCodeOpen(true);
    };

    function emailQRCode(row: ReservationType) {
        axios
            .post('link', {
                qrcode: `${row.hotelId}${SEPARATOR}${row.roomName}${SEPARATOR}${row.stayPass}`,
            })
            .then((response: AxiosResponse<DynamicLink>) => {
                const lockPINMessage = /lock/i.test(userInfo.subscriptionType) ? `Lock PIN code: ${row.lockPIN}\n\n` : '\n';

                window.location.href =
                    'mailto:' +
                    row.customerEmail +
                    '?subject=' +
                    encodeURIComponent(`Hotel ${hotel?.hotelName} Reservation Info`) +
                    '&body=' +
                    encodeURIComponent(
                        `Welcome ${row.customerTitle} ${row.customerName},\n\n` +
                            `In order to enjoy our services please open the following link.\n\n` +
                            `${response.data.link} \n\n` +
                            `${lockPINMessage}` +
                            `Alternatively you can manually download the HotelUp application from the following links and scan the QR code provided by the hotel reception. \n\n` +
                            `For Android: https://play.google.com/store/apps/details?id=com.panosdim.hotelup&hl=en&gl=US \n\n` +
                            `For iOS: https://apps.apple.com/lv/app/hotelup/id1579728292 \n\n`
                    );
            })
            .catch((error: AxiosError) => {
                handleError(error, 'Problem generating dynamic link.');
            });
    }

    function sendNotification(title: string, topic: string) {
        setNotifyTitle(title);
        setNotifyTopic(topic);
        setIsNotifyOpen(true);
    }

    return (
        <>
            <HeaderNavigation />
            <Grid
                sx={{ width: '100%', paddingRight: theme.spacing(2), paddingLeft: theme.spacing(2) }}
                container
                spacing={1}
                justifyContent="space-between">
                <Grid item xs={12}>
                    <Paper sx={{ padding: theme.spacing(2) }}>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h3">Reservations for {hotel?.hotelName}</Typography>
                            </Grid>
                            <Grid item>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        sx={{ marginInlineEnd: theme.spacing(2) }}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => hotel?.id && history.push(`/hotels/rooms/${hotel.id}`)}>
                                        Rooms Manager
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => hotel?.id && sendNotification('Notify all residents', hotel?.id)}>
                                        Notify All
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ width: '100%' }}>
                        <TableContainer component={Paper} style={{ maxHeight: 650 }}>
                            <Table stickyHeader aria-label="Reservations">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Room Name</TableCell>
                                        <TableCell align="center">Room Active</TableCell>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Customer Name</TableCell>
                                        <TableCell>Customer Email</TableCell>
                                        <TableCell>Check In</TableCell>
                                        <TableCell>Check Out</TableCell>
                                        {/lock/i.test(userInfo.subscriptionType) && <TableCell align="right">Lock PIN</TableCell>}
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading ? (
                                        <TableRow key="loading">
                                            <TableCell colSpan={7} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        reservations &&
                                        reservations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            <TableRow
                                                hover
                                                key={row.id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    backgroundColor: dayjs(row.checkOut, 'YYYY-MM-DD').isBefore(dayjs())
                                                        ? red[500]
                                                        : 'none',
                                                }}
                                                onClick={() => row.roomActive && editReservation(row)}>
                                                <TableCell>{row.roomName}</TableCell>
                                                <TableCell align="center">
                                                    {row.roomActive ? (
                                                        <CheckCircleIcon fontSize="small" />
                                                    ) : (
                                                        <CancelIcon fontSize="small" style={{ color: red[500] }} />
                                                    )}
                                                </TableCell>
                                                <TableCell>{row.customerTitle}</TableCell>
                                                <TableCell>{row.customerName}</TableCell>
                                                <TableCell>{row.customerEmail}</TableCell>
                                                <TableCell>
                                                    {row.checkIn && dayjs(row.checkIn, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                                </TableCell>
                                                <TableCell>
                                                    {row.checkOut && dayjs(row.checkOut, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                                </TableCell>
                                                {/lock/i.test(userInfo.subscriptionType) && (
                                                    <TableCell align="right">
                                                        {row.roomLock ? (
                                                            row.lockPIN
                                                        ) : (
                                                            <CancelIcon fontSize="small" style={{ color: red[500] }} />
                                                        )}
                                                    </TableCell>
                                                )}
                                                <TableCell>
                                                    {row.stayPass && (
                                                        <Stack spacing={1} justifyContent="center" direction="row">
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    showQRCode(row);
                                                                }}>
                                                                Show QR Code
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    emailQRCode(row);
                                                                }}>
                                                                Email QR Code
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    sendNotification(`Notify resident ${row.customerName}`, row.stayPass);
                                                                }}>
                                                                Notify
                                                            </Button>
                                                        </Stack>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={reservations.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Dialog maxWidth={'xs'} onClose={() => setIsQRCodeOpen(false)} open={isQRCodeOpen}>
                <DialogTitle>Reservation Info</DialogTitle>
                <DialogContent dividers>
                    <QRCode value={qrCode} />
                </DialogContent>

                <DialogActions
                    sx={{
                        '@media print': {
                            display: 'none',
                        },
                    }}>
                    <Stack spacing={1} sx={{ width: '100%' }} direction="row" justifyContent="space-between">
                        <Button variant="contained" color="primary" onClick={() => window.print()}>
                            Print
                        </Button>

                        <Button variant="outlined" onClick={() => setIsQRCodeOpen(false)}>
                            Cancel
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

            {isNotifyOpen && <NotificationForm handleClose={() => setIsNotifyOpen(false)} title={notifyTitle} topic={notifyTopic} />}
            <ReservationForm open={isReservationModalOpen} reservation={reservation} handleOperation={handleReservationOperation} />
        </>
    );
}

export default Reservations;
