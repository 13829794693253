import { HotelNotification, LocalStorageData, RestaurantNotification, UserInfoType } from '../model/data';
import { emptyUserInfo } from './const';

export function storeUUID(uuid: string) {
    localStorage.setItem('uuid', uuid);
}

export function getUUID(): string | null {
    return localStorage.getItem('uuid');
}

export function deleteUUID() {
    localStorage.removeItem('uuid');
}

export function getRegistrationToken(): string | null {
    return localStorage.getItem('registration_token');
}

export function setRegistrationToken(token: string) {
    localStorage.setItem('registration_token', token);
}

export function getLocalStorageData(): LocalStorageData | null {
    const uuid = getUUID();
    if (uuid) {
        const localData = localStorage.getItem(uuid);
        if (localData) {
            return JSON.parse(localData) as LocalStorageData;
        }
    }
    return null;
}

export function storeLocalStorageData(localData: LocalStorageData) {
    const uuid = getUUID();
    if (uuid) {
        localStorage.setItem(uuid, JSON.stringify(localData));
    }
}

export function storeUser(user: UserInfoType) {
    storeUUID(user.uuid);

    // Get current local storage data
    let localData = getLocalStorageData();
    if (localData) {
        localData.user = user;
    } else {
        localData = {
            user: user,
            restaurantNotifications: [],
            hotelNotifications: [],
        };
    }

    storeLocalStorageData(localData);
}

export function getUser(): UserInfoType {
    const localData = getLocalStorageData();
    if (localData) {
        return localData.user;
    }

    return emptyUserInfo;
}

export function storeHotelNotifications(hotelNotifications: HotelNotification[]) {
    // Get current local storage data
    let localData = getLocalStorageData();
    if (localData) {
        localData.hotelNotifications = hotelNotifications;
        storeLocalStorageData(localData);
    }
}

export function getHotelNotifications(): HotelNotification[] {
    const localData = getLocalStorageData();
    if (localData) {
        return localData.hotelNotifications;
    }

    return [];
}

export function storeRestaurantNotifications(restaurantNotifications: RestaurantNotification[]) {
    // Get current local storage data
    let localData = getLocalStorageData();
    if (localData) {
        localData.restaurantNotifications = restaurantNotifications;
        storeLocalStorageData(localData);
    }
}

export function getRestaurantNotifications(): RestaurantNotification[] {
    const localData = getLocalStorageData();
    if (localData) {
        return localData.restaurantNotifications;
    }

    return [];
}

export function getAuthorizationBearer(): string | null {
    const localData = getLocalStorageData();
    if (localData) {
        return localData.user.token;
    }
    return null;
}
